
import axios from "axios";
// import { useCookies } from "react-cookie";

let alertShown = false;

function setCookie(cname, cvalue) {
    const d = new Date();
    // d.setTime(d.getTime() + (exdays*24*60*60*1000));
    // let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + ";path=/";
}

axios.interceptors.response.use(
    (response) => {
        console.log("response", response);
        return response;
    },
    (error) => {
        console.log("error", error);
        if (error.response && error.response.status === 401) {
            const errorData = error.response.data;

            if (errorData.error === "TokenExpiredError") {
                console.error("Token expired:", errorData.message);

                if (!alertShown) {
                    alert("Session expired. Please log in again.");
                    alertShown = true;

                    setCookie("token","");
                    setCookie("designation","");
                    setCookie("permission","");
                    setCookie("useremail","");
                    setCookie("accessRole","");
                    setCookie("username","");
                    // const [cookies, setCookie, removeCookie] = await useCookies(["token", "accessRole", "designation", "permission", "useremail", "username"]);
                    // await removeCookie("token");
                    // await removeCookie("designation")
                    // await removeCookie("permission")
                    // await removeCookie("useremail")
                    // await removeCookie("accessRole")
                    // await removeCookie("username")
                    window.location.href = "/";

                    return Promise.reject(error);
                }
            }
        }


        return Promise.reject(error);
    }
);

export default axios;
