import React, { useEffect, useState } from "react";
import "./BasicDetails.scss";
import dayjs from "dayjs";


const BasicDetails = ({
    setPassWordChange,
    setEditBasicDetails,
    userInfo
}) => {

    const locations = {
        "63932c7ab1cab628335ae969": "Jengjal",
        "63930f82865ec3abed90bc1b": "Mendipathar PHC",
        "639310eb865ec3abed90bc1f": "Pedaldoba PHC",
        "639311e2865ec3abed90bc23": "Nongalbibra PHC",
        "6393126c865ec3abed90bc27": "Shallang PHC",
        "639312ff865ec3abed90bc2b": "Samanda PHC",
        "63bd5b3ccafee0c35772f8f1": "Office",
    }


    return (
        <div className="MyProfileBasicDetails-SubConatiner__DataConatiner">
            <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__subContainer">
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__title">
                    <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__title__text">Basic Details</div>
                    <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__title__icon" onClick={() => { setEditBasicDetails(true) }} >
                        <svg className="MyProfileBasicDetails-SubConatiner__DataConatiner__title__icon__edit-icon" width="22" height="22" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8344 1.54314C11.0066 1.37094 11.211 1.23435 11.436 1.14116C11.661 1.04796 11.9021 1 12.1456 1C12.3891 1 12.6303 1.04796 12.8553 1.14116C13.0802 1.23435 13.2847 1.37094 13.4569 1.54314C13.6291 1.71533 13.7657 1.91976 13.8588 2.14474C13.952 2.36973 14 2.61086 14 2.85439C14 3.09791 13.952 3.33904 13.8588 3.56403C13.7657 3.78901 13.6291 3.99344 13.4569 4.16563L4.60593 13.0166L1 14L1.98344 10.3941L10.8344 1.54314Z" stroke="#BCBFCB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                        {/* <span className="material-symbols-outlined MyProfileBasicDetails-SubConatiner__DataConatiner__title__edit-icon">
                            edit
                        </span> */}
                    </div>
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__label">
                    Name
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__value">
                    {userInfo.userName}

                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__label">
                    Email
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__value">
                    {userInfo.useremail}
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__label">
                    Phone No
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__value">
                    {userInfo.phone_no ? userInfo.phone_no : "Not Available"}
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__label">
                    Gender
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__value">
                    {userInfo.gender ? userInfo.gender : "Not Available"}
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__label">
                    Date of Birth
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__value">
                    {userInfo.date_birth ? dayjs(userInfo.date_birth).format("YYYY-MM-DD").toString() : "DD/MM/YY"}
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__label">
                    Connected Hub
                </div>
                <div className="MyProfileBasicDetails-SubConatiner__DataConatiner__value">
                    {userInfo.location ? locations[userInfo.location] : "Not Available"}
                </div>
                <button className="MyProfileBasicDetails-SubConatiner__DataConatiner__chngPassBtn" onClick={() => { setPassWordChange(true) }}>Change Password</button>
            </div>
        </div>

    );
};

export default BasicDetails;
