import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react'
import "./SelfOrderModel.scss"
import { CustomerDetailContext } from '../../context/CustomerDetailContext';
import { useNavigate } from 'react-router-dom';
import { DatePicker, Form, Radio, RadioGroup, Stack } from 'rsuite';
import axios from 'axios';
import { backEndIP, clearskyIP } from '../configuration';
import { checkWeightLimitFunction } from '../../Utility/checkWeightLimitFunction';
import { fetchOrderDeliverySlots } from '../../Utility/fetchOrderDeliverySlots';
const SelfOrderModel = ({
    userHubLocation,
    myLocation,
    allLocations,
    setSelfOrderDetailsPopup,
    handleNotifyAlert,
    SetLoadingVisibility,
    userCookie,
    setCreateOrderHome,
    setSelectedTab,
     setSelectedSubContent,
    setSubContent,
    myHubLocation
}) => {
    const phoneNoElementRef = useRef()

    const [avaiblableDestLocations, setAvaiblableDestLocations] = useState({});
    const { payloadDetails, setPayloadDetails, source_location, set_source_location, destination_location, set_destination_location, deliveryDate, setDeliveryDate, orderType, setOrderType, deliveryType, setDeliveryType, orderDeliveryType, setOrderDeliveryType, sourceData, setSourceData, destinationData, setDestinationData, customerData, setCustomerData, destLocation, setDestLocation, recievedOn, setRecievedOn, urgentReason, setUrgentReason, availableOrderSlots, setAvailableOrderSlots, setSelectOrderView, flightRequired, setFlightRequired, flightType, setFlightType } = useContext(CustomerDetailContext)
    const [emailError, setEmailError] = useState(false)
    const [PhoneNoError, setPhoneNoError] = useState(false)
    const [hubLocation, setHubLocation] = useState({ location_id: userHubLocation, location_name: "" })
    const [orderNumber, setOrderNumber] = useState(0);
    // const [orderPayload, setOrderPayload] = useState(0);
    const [userData, setUserData] = useState("")
    // const [source_location, set_source_location] = useState("");
    // const [destination_location, set_destination_location] = useState("");
    const [payloadTypeError, setPayloadTypeError] = useState("");
    const [destinationLocationError, setDestinationLocationError] = useState("");
    const [sourceLocationError, setSourceLocationError] = useState("");
    const [payloadWeightError, setPayloadWeightError] = useState("");
    const [selectDateVisibility, setSelectDateVisibility] = useState("");
    const [recievedOnError, setRecievedOnError] = useState("")
    const [orderDeliveryTypeError, setOrderDeliveryTypeError] = useState("");
    const [deliveryTypeError, setDeliveryTypeError] = useState("");
    const [urgentReasonError, setUrgentReasonError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [flightTypeError, setFlightTypeError] = useState("");
    const emailFormate = /^\w+([\.-_]?\w+)*@\w*(\.\w{2,3})+$/
    const [errorCheck, setErrorCheck] = useState("")
    const navigate = useNavigate();
    // const [availabelOrderSlots, setAvailableOrderSlots] = useState([]);
    const urgentReasonOption = [
        { label: "Life risk" },
        { label: "Critical supplies" }
    ]
    useEffect(()=>{
        if(!myHubLocation.flags.selfOrder){
            setSelfOrderDetailsPopup(false);
            setCreateOrderHome(true);
        }
      },[])
    useEffect(() => {
        if (userCookie.token === "" || !userCookie.token) {
            navigate("/");
            return;
        }
        if (userCookie.token) {
            axios({
                url: backEndIP + "/user/verifytoken",
                method: "GET",
                headers: {
                    authorization: userCookie.token
                },
            }).then(async (loginData) => {
                console.log("loginData", loginData);
                setUserData(loginData.data)
            }).catch((err) => {
                console.log("err", err)
            })

        }
        else {
            navigate("/")
        }
    }, [])
    // useEffect(() => {
    //     set_source_location(
    //         (orderDeliveryType === "POC" || orderDeliveryType === "Test")
    //             ? sourceData
    //             : orderDeliveryType === "Diagnostics(Reverse)"
    //                 ? destLocation.split(",")[0]
    //                 : userHubLocation
    //     );

    //     set_destination_location(
    //         (orderDeliveryType === "POC" || orderDeliveryType === "Test")
    //             ? destinationData
    //             : orderDeliveryType === "Diagnostics(Reverse)"
    //                 ? userHubLocation
    //                 : destLocation.split(",")[0]
    //     );
    // }, [orderDeliveryType, sourceData, destLocation, userHubLocation, destinationData]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchOrderDeliverySlots(userCookie, setAvailableOrderSlots, handleNotifyAlert);
            } catch (error) {
                console.error("Error fetching delivery slots:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        // console.log(deliveryDate, destLocation)
        const tempMyLoc = {}
        allLocations.forEach((loc, i) => {
            if (loc._id === myLocation) {
                tempMyLoc[myLocation] = loc.location_name;
                tempMyLoc[loc.location_name] = loc._id;
            }
            if (allLocations[i]._id === userHubLocation) {
                setHubLocation({ location_id: userHubLocation, location_name: `${allLocations[i].location_name} (${allLocations[i].tag_name})` })
            }
        })
        setAvaiblableDestLocations(tempMyLoc)

    }, [allLocations])

    const PlaceUserOrder = () => {
        console.log({
            orderType: orderType,
            cart: [],
            source_location: orderDeliveryType === "Diagnostics(Reverse)" ? destLocation.split(",")[0] : userHubLocation,
            destination_location: orderDeliveryType === "Diagnostics(Reverse)" ? userHubLocation : destLocation.split(",")[0],
            delivery_date: deliveryDate,
            delivery_type: deliveryType,
            payloadDetails: payloadDetails,
            order_delivery_type: orderDeliveryType,
            // source_location:source_location,
            hubLocation: userHubLocation,
            customerData: { name: userData.userName, email: userData.userEmail, phoneNo: userData.phoneNumber }
        })
        SetLoadingVisibility(true);

        axios({
            url: backEndIP + "/order/placed-manual-order",
            method: "POST",
            headers: {
                authorization: userCookie.token,
            },
            data: {
                // orderType: orderType,
                source_location: orderDeliveryType === "Diagnostics(Reverse)" || flightType === FLIGHT_TYPES.REVERSE ? destLocation.split(",")[0] : userHubLocation,
                destination_location: orderDeliveryType === "Diagnostics(Reverse)" || flightType === FLIGHT_TYPES.REVERSE ? userHubLocation : flightType === FLIGHT_TYPES.TURN_AROUND ? userHubLocation : destLocation.split(",")[0],
                delivery_date: deliveryDate,
                delivery_type: deliveryType,
                orderNo: orderNumber,
                recievedOn: orderDeliveryType === "Diagnostics(Reverse)" ? recievedOn : orderType,
                order_delivery_type: orderDeliveryType,
                payloadDetails: payloadDetails,
                hubLocation: userHubLocation,
                urgentReason: urgentReason !== "Select" ? urgentReason : null,
                customerData: { name: userData.userName, email: userData.userEmail, phoneNo: userData.phoneNumber },
                flightRequired: flightRequired,
                flightType: flightType
            }
            // data: { id: product._id , quantity:1 }
        })
            .then((resp) => {
                SetLoadingVisibility(false);
                // console.log(resp.data);
                handleNotifyAlert("notify", "Order placed succesfully", "success", () => { })
                setDestLocation("Select")
                setDeliveryType("")
                setCustomerData({ name: "", email: "", phoneNo: "" })
                setOrderType("Self")
                setSelectDateVisibility(false)
                setOrderNumber(0);
                setRecievedOn("");
                setOrderDeliveryType("Select")
                setDestinationData({ name: "", lat: "", long: "" })
                setSourceData({ name: "", lat: "", long: "" })
                setPayloadDetails({ type: "", description: "", weight: 0 })
                setSelfOrderDetailsPopup(false);
                setCreateOrderHome(true);
                setDeliveryDate("")
                setFlightRequired(2)
                setFlightType("Forward-Return")
                if (userCookie.permission === "Granted") {
                    navigate("/manage-orders")
                    setSubContent("Manage Orders")
                    setSelectOrderView("Manual Orders")
                    setSelectedTab("Manage Orders")
                    
                }
            })
            .catch((err) => {
                SetLoadingVisibility(false);
                console.log(err)
                if (err.response.data.message) {
                    // window.alert(err.response.data.message);
                    handleNotifyAlert("notify", err.response.data.message, "failed", () => { })
                }
                else handleNotifyAlert("notify", "some error occurered", "failed", () => { });
                //console.log(err);
                fetchOrderDeliverySlots(userCookie, setAvailableOrderSlots, handleNotifyAlert);
            });
    }
    const handlePlaceOrderClick = () => {
        //  console.log(phoneNoElementRef.current.value
        console.log("working", handleSelfOrderDetails());
        if (!handleSelfOrderDetails()) {
            return;
        }
        console.log("working");
        if (orderType === "Self") {
            // console.log(phoneNoElementRef.current.value)
            // if (customerData.name === "" && customerData.email === "" && phoneNoElementRef === "" && recievedOn === "") {
            //     handleNotifyAlert("notify", "Please fill all the customers details", "alert", () => { })
            // } else if (phoneNoElementRef.current.value.length !== 10) {
            //     setPhoneNoError(true)
            //     handleNotifyAlert("notify", "Enter Vaild Phone no", "alert", () => { })
            // } 
            // else if (customerData.email.match(emailFormate) === null) {
            //     setEmailError(true)
            //     handleNotifyAlert("notify", "Enter User's Vaild Email", "alert", () => { })
            // }
            // else {
            // const tempCustomerData ={ ...customerData, phoneNo: `+91${phoneNoElementRef.current.value}` }
            // setCustomerData(tempCustomerData)
            console.log("destination_location", destLocation);
            if (destination_location !== "Select" && deliveryType !== "") {
                console.log("customerData", customerData)
                handleNotifyAlert("alert", `Are you sure you want to place order for Destination location : 
                    ${(orderDeliveryType === "Diagnostics(Reverse)" && flightType === FLIGHT_TYPES.REVERSE) ? hubLocation.location_name : flightType === FLIGHT_TYPES.TURN_AROUND? hubLocation.location_name: destLocation.split(",")[1]}?`, "alert", PlaceUserOrder)
                setSelfOrderDetailsPopup(true);
            }
            else if (destination_location === "Select") {
                handleNotifyAlert("notify", "Please select destination Location", "alert", () => { })
                setSelfOrderDetailsPopup(true);
            }
            else if (deliveryType === "") {
                handleNotifyAlert("notify", `Please select "delivery type" for the order`, "alert", () => { })
                setSelfOrderDetailsPopup(true);
            }
            // else {
            //     SetLoadingVisibility(false);
            //     handleNotifyAlert("notify", "Please update Cart first", "alert", () => { })
            // }
        }
        // }
    }
    const allrecievedOn = [
        "Whatsapp", "Other", "Email",
        // "Shopify"
    ]
    const handleSelfOrderModelClose = () => {
        setSelfOrderDetailsPopup(false);
        setCreateOrderHome(true)
        // setDestLocation("Select")
        // setDeliveryType("")
        // setCustomerData({ name: "", email: "", phoneNo: "" })
        // setOrderType("Self")
        // setSelectDateVisibility(false)
        // setOrderNumber(0);
        // setRecievedOn("");
        // setOrderDeliveryType("Select")
        // setDestinationData({ name: "", lat: "", long: "" })
        // setSourceData({ name: "", lat: "", long: "" })
        // setPayloadDetails({ type: "", description: "", weight: 0 })
        // setPayloadTypeError("");
        // setDestinationLocationError("");
        // setSourceLocationError("");
        // setPayloadWeightError("");
        // setSelectDateVisibility("");
        // setRecievedOnError("")
        // setOrderDeliveryTypeError("");
    }

    // Constants for validation
    const ORDER_TYPES = {
        DIAGNOSTICS_REVERSE: "Diagnostics(Reverse)",
        UNLOCKING_FLIGHT: "Unlocking Flight",
        POC: "POC",
        TEST: "Test",
        SELECT: "Select",
    };

    const FLIGHT_TYPES = {
        FORWARD_REVERSE: "Forward-Return",
        FORWARD: "Forward",
        REVERSE: "Return",
        TURN_AROUND: "Turn Around",
        SELECT: "Select",
    };


    function handleSelfOrderDetails() {
        let hasError = false;
        let errorMessages = [];

        const setError = (message, errorSetter) => {
            console.log("message", message, errorSetter)
            errorMessages.push(message);
            errorSetter(message);
            hasError = true;
        };
        console.log("destLocation", destLocation);
        const WEIGHT_LIMIT = 2500;
        const DESCRIPTION_MIN_LENGTH = 6;

        if (orderDeliveryType === ORDER_TYPES.SELECT) {
            console.log("destLocation", orderDeliveryType);
            setError("Please select order delivery type", setOrderDeliveryTypeError);
        } else {
            console.log("destLocation", orderDeliveryType);
            if (destLocation === ORDER_TYPES.SELECT) {
                setError("Please select destination name", setDestinationLocationError);
            }

            if (orderDeliveryType === ORDER_TYPES.DIAGNOSTICS_REVERSE && recievedOn === "") {
                setError("Please select received on", setRecievedOnError);
            }
            console.log("destLocation", orderDeliveryType);
            if (orderDeliveryType === ORDER_TYPES.POC || orderDeliveryType === ORDER_TYPES.TEST) {
                console.log("flightRequired", flightRequired);
                if (flightRequired === 1) {
                    console.log("flightType", flightType);
                    if (flightType === FLIGHT_TYPES.SELECT || flightType === FLIGHT_TYPES.FORWARD_REVERSE) {
                        setError("Please select flight type", setFlightTypeError);
                    }
                }
            }
        }
        console.log("destLocation", orderDeliveryType);
        if (!payloadDetails.type || payloadDetails.weight === 0) {
            if (!payloadDetails.type) setError("Please enter a valid type", setPayloadTypeError);
            if (payloadDetails.weight === 0) setError("Please enter a valid weight", setPayloadWeightError);
        }
        console.log("destLocation", orderDeliveryType);
        if (!payloadDetails.description || payloadDetails.description.length < DESCRIPTION_MIN_LENGTH) {
            setError(`Description must be at least ${DESCRIPTION_MIN_LENGTH} characters`, setDescriptionError);
        }
        console.log("destLocation", orderDeliveryType);
        if (checkWeightLimitFunction(payloadDetails.weight)) {
            setError(`Weight exceeds limit of ${WEIGHT_LIMIT}`, setPayloadWeightError);
        }
        console.log("destLocation", orderDeliveryType);
        if (!deliveryType) {
            console.log("deliveryType", deliveryType);
            setError("Delivery type is required", setDeliveryTypeError);
        } else if (deliveryType === "Urgent" && urgentReason === "Select") {
            console.log("deliveryType", deliveryType);
            setError("Please select an urgent reason", setUrgentReasonError);
        }
        console.log("destLocation", orderDeliveryType);
        if (hasError) {
            console.log("hasError", hasError);
            setErrorCheck(errorMessages.join(", "));
            return false;
        }
        console.log("destLocation", orderDeliveryType);
        setSelfOrderDetailsPopup(false);
        return true;
    }

    const handleFlightRadioChange = (value) => {
        // console.log("value",value)
        // console.log("flightType",flightType)
        if (value === 2) {
            setFlightType("Forward-Return")
            setFlightTypeError("")
        } else {
            setFlightType("Select")
            setFlightTypeError("Please select flight type")
            // setError("Please select flight type", setFlightTypeError);
        }
        // console.log("value",value)
        // console.log("flightType",flightType)
        setFlightRequired(value);
    };

    console.log("flightType", flightType)

    const handleDeliveryRadioChange = (value) => {
        setDeliveryType(value);
        if (value === "Urgent") {
            const currentDate = new Date();
            const currentHour = currentDate.getHours();
            const currentMinutes = currentDate.getMinutes();

            let deliveryDate = new Date(currentDate);

            if (currentHour < 12 || (currentHour === 12 && currentMinutes === 0)) {
                deliveryDate.setHours(18, 0, 0, 0);
            } else {
                deliveryDate.setDate(deliveryDate.getDate() + 1);
                deliveryDate.setHours(12, 0, 0, 0);
            }

            const formattedDate = dayjs(deliveryDate).format("MM/DD/YYYY hh:mm A"); // Format the date
            console.log("formattedDate", formattedDate);
            setSelectDateVisibility(false);
            setDeliveryDate(formattedDate); // Set the formatted delivery date

        } else {
            setSelectDateVisibility(true);

        }
        setDeliveryTypeError("")
        // console.log("deliverytype", deliveryType);
    };
    const allOrderDeliveryTypes = ["Diagnostics(Reverse)", "POC", "Unlocking Flight", "Test"];
    const singleFlight = ["POC", "Test"]
    const flightTypeArray = ["Forward", "Return", "Turn Around"]
    // console.log(flightRequired, "213")
    return (
        <div className='selfOrderModel'>
            <h3 className='selfOrderModel__heading'>Self Order Details</h3>
            <div className="selfOrderModel__totalItems">
                <div className="selfOrderModel__totalItems__locationCont1 ">
                    <div className="selfOrderModel__totalItems__locationCont1__label"> My Details</div>
                    <div className="selfOrderModel__customerInput">
                        <div className="selfOrderModel__customerInput__label">Name</div>
                        <div className="selfOrderModel__customerInput__value">
                            <input type="text"
                                value={userData.userName}
                                placeholder="Enter name"
                                className="selfOrderModel__customerInput__value__input"
                                // onChange={(e) => {
                                //     // setCustomerData({ ...user, "name": e.target.value })
                                //     setErrorCheck("")
                                // }}
                                readOnly
                            /></div>
                    </div>
                    <div className="selfOrderModel__customerInput" >
                        <div className="selfOrderModel__customerInput__label">Email</div>
                        <div className="selfOrderModel__customerInput__value" >
                            <input
                                type="email"

                                value={userData.userEmail}
                                style={{ color: emailError === true ? "red" : "var(--primaryTextColor)" }}
                                placeholder="Enter Email"
                                className="selfOrderModel__customerInput__value__input"
                                onChange={(e) => {
                                    setCustomerData({ ...customerData, "email": e.target.value })
                                    setEmailError(false)
                                    setErrorCheck("")
                                }}

                            /></div>
                    </div>
                    <div className="selfOrderModel__customerInput">
                        <div className="selfOrderModel__customerInput__label">Phone</div>
                        <div className="selfOrderModel__customerInput__value">

                            <input
                                ref={phoneNoElementRef}
                                type="text" // Change to text for better control
                                value={userData.phoneNumber} // Display only the number
                                style={{ borderColor: PhoneNoError ? "red" : "var(--primaryTextColor)" }}
                                placeholder="Enter Phone no"
                                className="selfOrderModel__customerInput__value__input2"
                                // onChange={(e) => {
                                //     const input = e.target.value;
                                //     setErrorCheck("")
                                //     // Allow only numeric values
                                //     const sanitizedInput = input.replace(/[^0-9]/g, "");

                                //     if (sanitizedInput.length <= 10) {
                                //         setPhoneNoError(false);
                                //         const formattedPhone = `+91${sanitizedInput}`;
                                //         setCustomerData({ ...customerData, "phoneNo": formattedPhone });
                                //     }
                                // }}
                                readOnly
                            />

                        </div>
                    </div>
                    {/* <div className="selfOrderModel__customerInput" >
                    <div className="selfOrderModel__customerInput__label">Recieved On</div>
                    <div className="selfOrderModel__customerInput__value">
                        <select

                            value={recievedOn}
                            onChange={(e) => {
                                setRecievedOn(e.target.value)

                                setErrorCheck("")
                            }}
                            required
                            placeholder="Select Manual Order Type"
                            className="selfOrderModel__customerInput__value__input2 selfOrderModel__customerInput__value__input1"
                        >
                           
                        </select>
                    </div>
                </div> */}

                </div>
                <div className="selfOrderModel__Item2">
                    <div className="selfOrderModel__inputFiled" style={{ border: (orderDeliveryTypeError && orderDeliveryType === "Select") ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                        <div className="selfOrderModel__inputFiled__label">Order Delivery Type <span style={{ color: "red" }}>*</span></div>
                        <div className="selfOrderModel__inputFiled__value">
                            <select
                                value={orderDeliveryType}
                                onChange={(e) => {
                                    if (e.target.value === "Select") {
                                        setErrorCheck("All fields required")
                                        setOrderDeliveryTypeError("Please select order delivery type");
                                    } else {
                                        setErrorCheck("")
                                        setOrderDeliveryTypeError("");
                                    }
                                    setOrderDeliveryType(e.target.value)

                                }}
                                //defaultValue={destLocation}
                                //placeholder="Enter Confirm Password"
                                className="selfOrderModel__inputFiled__value__dropDown"
                            >
                                <option value={"Select"}>
                                    Select
                                </option>
                                {allOrderDeliveryTypes.map((option) => {
                                    return <option key={option} value={option}>
                                        {option}
                                    </option>
                                })}</select>
                        </div>
                        <div className='selfOrderModel__inputFiled__error'>{orderDeliveryTypeError}</div>
                    </div>
                    {singleFlight.includes(orderDeliveryType) && <div className="selfOrderModel__flightDetails">
                        <div className="selfOrderModel__flightDetails__noFlightReq">
                            <div className="selfOrderModel__flightDetails__noFlightReq__lable">No Of flight:</div>
                            <div className="selfOrderModel__flightDetails__noFlightReq__radio"><RadioGroup
                                inline
                                // name="controlled-radio-buttons-group"
                                defaultValue={flightRequired}
                                name="radio-group-inline"
                                value={flightRequired}
                                onChange={handleFlightRadioChange} >
                                <Radio value={1}>
                                    1
                                </Radio>
                                <Radio value={2}>
                                    2
                                </Radio>
                            </RadioGroup></div>
                        </div>
                        {flightRequired === 1 ? <div className="selfOrderModel__flightDetails__noFlightReq">
                            <div className="selfOrderModel__inputFiled" style={{ border: (flightTypeError && flightType === "Select") ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                                <div className="selfOrderModel__inputFiled__label">Flight Type<span style={{ color: "red" }}>*</span></div>
                                <div className="selfOrderModel__inputFiled__value">
                                    <select
                                        value={flightType}
                                        onChange={(e) => {
                                            if (e.target.value === "Select") {
                                                setErrorCheck("All fields required")
                                                setFlightTypeError("Please select flight type");
                                            } else {
                                                setErrorCheck("")
                                                setFlightTypeError("");
                                            }
                                            if (e.target.value === FLIGHT_TYPES.TURN_AROUND) {
                                                setDestLocation(userHubLocation)
                                            }
                                            setFlightType(e.target.value)
                                        }}
                                        //defaultValue={destLocation}
                                        //placeholder="Enter Confirm Password"
                                        className="selfOrderModel__inputFiled__value__dropDown"
                                    >
                                        <option value={"Select"}>
                                            Select
                                        </option>
                                        {flightTypeArray.map((option) => {
                                            return <option key={option} value={option}>
                                                {option}
                                            </option>
                                        })}</select>
                                </div>
                                <div className='selfOrderModel__inputFiled__error'>{flightTypeError}</div>
                            </div>
                        </div> :
                            <div className="selfOrderModel__flightDetails__noFlightReq">
                                <div className="selfOrderModel__inputFiled" style={{ border: (flightTypeError && flightType === "Select") ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                                    <div className="selfOrderModel__inputFiled__label">Flight Type</div>
                                    <div className="selfOrderModel__inputFiled__value">
                                        <input
                                            value={flightType}
                                            readOnly
                                            //defaultValue={destLocation}
                                            //placeholder="Enter Confirm Password"
                                            className="selfOrderModel__inputFiled__value__dropDown"
                                        />
                                        {/* <option value={"Select"}>
                                        Select
                                    </option>
                                    {flightTypeArray.map((option) => {
                                        return <option key={option} value={option}>
                                            {option}
                                        </option>
                                    })}</select> */}
                                    </div>
                                    <div className='selfOrderModel__inputFiled__error'>{flightTypeError}</div>
                                </div>
                            </div>
                        }
                    </div>}
                    {orderDeliveryType === "Diagnostics(Reverse)" &&
                        // <div className="selfOrderModel__inputFiled">
                        //                 <div className="selfOrderModel__inputFiled__label">Recieved On<span style={{ color: "red" }}>*</span></div>
                        //                 <div className="selfOrderModel__inputFiled__value">
                        //                     <input
                        //                         value={recievedOn}
                        //                         onChange={(e) => {
                        //                             setRecievedOn(e.target.value)
                        //                             setErrorCheck("")
                        //                         }}
                        //                         type='text'
                        //                         placeholder="Enter Recieved On"
                        //                         className="selfOrderModel__inputFiled__value__input"
                        //                     /></div>
                        //             </div> 
                        // :
                        <div className="selfOrderModel__inputFiled" style={{ border: (recievedOnError !== "") ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                            <div className="selfOrderModel__inputFiled__label">Recieved On <span style={{ color: "red" }}>*</span></div>
                            <div className="selfOrderModel__inputFiled__value">
                                <select
                                    value={recievedOn}
                                    onChange={(e) => {
                                        if (e.target.value === "Select") {
                                            setErrorCheck("All fields required")
                                            setRecievedOnError("Please Select Recieved On");
                                        } else {
                                            setErrorCheck("")
                                            setRecievedOnError("");
                                        }
                                        setRecievedOn(e.target.value)

                                        // setErrorCheck("")?
                                    }}
                                    required

                                    className="selfOrderModel__inputFiled__value__input"
                                >
                                    <option value={"Select"}>
                                        Select
                                    </option>
                                    {allrecievedOn.map((option) => <option key={option} value={option}> {option}</option>)}
                                </select>
                            </div>
                            <div className='selfOrderModel__inputFiled__error'>{recievedOnError}</div>
                        </div>}
                </div>
                <div className="selfOrderModel__Item5">

                    {/* {(orderDeliveryType !== "POC" && orderDeliveryType !== "Test") ? */}
                    {(orderDeliveryType !== "Diagnostics(Reverse)" && flightType !== "Return") ?
                        <>
                            <div className="selfOrderModel__inputFiled">
                                <div className="selfOrderModel__inputFiled__label">{(orderDeliveryType !== "Diagnostics(Reverse)" && flightType !== "Return") ? "Source Location" : "Destination Location"}<span style={{ color: "red" }}>*</span></div>
                                <div className="selfOrderModel__inputFiled__value">
                                    <input
                                        readOnly
                                        value={hubLocation.location_name}
                                        //placeholder="Enter Confirm Password"
                                        className="selfOrderModel__inputFiled__value__input"
                                    /></div>
                            </div>

                            {flightType !== FLIGHT_TYPES.TURN_AROUND ? <div className="selfOrderModel__inputFiled" style={{ border: (destinationLocationError !== "" && destLocation === "Select") ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                                <div className="selfOrderModel__inputFiled__label">{(orderDeliveryType !== "Diagnostics(Reverse)" && flightType !== "Return") ? "Destination Location" : "Source Location"}<span style={{ color: "red" }}>*</span></div>
                                <div className="selfOrderModel__inputFiled__value">
                                    <select
                                        value={destLocation}
                                        onChange={(e) => {
                                            if (e.target.value === "Select") {
                                                setErrorCheck("All fields required")
                                                setDestinationLocationError("Please Select Destination Location");
                                            } else {
                                                setErrorCheck("")
                                                setDestinationLocationError("");
                                            }
                                            setDestLocation(e.target.value)

                                            console.log(e.target.value.split(",")[0]);
                                        }}
                                        className="selfOrderModel__inputFiled__value__dropDown"
                                    >
                                        <option value={"Select"}>
                                            Select
                                        </option>
                                        {allLocations.map((option) => {
                                            //console.log("option", option._id, option.location_name)
                                            if (option.tag_name !== "Office" && option.tag_name !== "Meghalaya")
                                                return <option key={option._id} value={[option._id, option.location_name]}>
                                                    {option.location_name}
                                                </option>
                                            else {
                                                return
                                            }
                                        })}</select>
                                </div>
                                <div className='selfOrderModel__inputFiled__error'>{destinationLocationError}</div>
                            </div> :
                                <div className="selfOrderModel__inputFiled">
                                    <div className="selfOrderModel__inputFiled__label">Destination Location<span style={{ color: "red" }}>*</span></div>
                                    <div className="selfOrderModel__inputFiled__value">
                                        <input
                                            readOnly
                                            value={hubLocation.location_name}
                                            //placeholder="Enter Confirm Password"
                                            className="selfOrderModel__inputFiled__value__input"
                                        /></div>
                                </div>
                            }
                        </> :
                        <>
                            <div className="selfOrderModel__inputFiled" style={{ border: (destinationLocationError !== "" && destLocation === "Select") ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                                <div className="selfOrderModel__inputFiled__label">{(orderDeliveryType !== "Diagnostics(Reverse)" && flightType !== "Return") ? "Destination Location" : "Source Location"}<span style={{ color: "red" }}>*</span></div>
                                <div className="selfOrderModel__inputFiled__value">
                                    <select
                                        value={destLocation}
                                        onChange={(e) => {
                                            if (e.target.value === "Select") {
                                                setErrorCheck("All fields required")
                                                setDestinationLocationError("Please Select Destination Location");
                                            } else {
                                                setErrorCheck("")
                                                setDestinationLocationError("");
                                            }
                                            setDestLocation(e.target.value)

                                            console.log(e.target.value.split(",")[0]);
                                        }}
                                        className="selfOrderModel__inputFiled__value__input"
                                    >
                                        <option value={"Select"}>
                                            Select
                                        </option>
                                        {allLocations.map((option) => {
                                            //console.log("option", option._id, option.location_name)
                                            if (option.tag_name !== "Office" && option.tag_name !== "Meghalaya")
                                                return <option key={option._id} value={[option._id, option.location_name]}>
                                                    {option.location_name}
                                                </option>
                                            else {
                                                return
                                            }
                                        })}</select>
                                </div>
                                <div className='selfOrderModel__inputFiled__error'>{destinationLocationError}</div>
                            </div>
                            <div className="selfOrderModel__inputFiled">
                                <div className="selfOrderModel__inputFiled__label">{(orderDeliveryType !== "Diagnostics(Reverse)" && flightType !== "Return") ? "Source Location" : "Destination Location"}<span style={{ color: "red" }}>*</span></div>
                                <div className="selfOrderModel__inputFiled__value">
                                    <input
                                        readOnly
                                        value={hubLocation.location_name}
                                        //placeholder="Enter Confirm Password"
                                        className="selfOrderModel__inputFiled__value__input"
                                    /></div>
                            </div></>}


                    {/* </div> */}

                    {/* <div className='selfOrderModel__Item4'> */}
                    {/* {recievedOn !== "TecheagleOMS" && <> */}
                    <div className="selfOrderModel__inputFiled" style={{ border: (payloadTypeError !== "" && payloadDetails.type.length < 3) ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                        <div className="selfOrderModel__inputFiled__label">Payload Type<span style={{ color: "red" }}>*</span></div>
                        <div className="selfOrderModel__inputFiled__value">
                            <input
                                value={payloadDetails.type}
                                onChange={(e) => {
                                    if (e.target.value.length < 3) {
                                        setErrorCheck("All fields required")
                                        setPayloadTypeError("Length must be > 3")
                                    } else {
                                        setErrorCheck("")
                                        setPayloadTypeError("")
                                    }
                                    setPayloadDetails({ ...payloadDetails, type: e.target.value })
                                }}
                                type='text'
                                placeholder="Enter payload type"
                                className="selfOrderModel__inputFiled__value__input"
                            /></div>
                        <div className='selfOrderModel__inputFiled__error'>{payloadTypeError}</div>
                    </div>

                    <div className="selfOrderModel__inputFiled" style={{ border: (descriptionError !== "") ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                        <div className="selfOrderModel__inputFiled__label">Payload Description<span style={{ color: "red" }}>*</span></div>
                        <div className="selfOrderModel__inputFiled__value">
                            <input
                                value={payloadDetails.description}
                                onChange={(e) => {
                                    setPayloadDetails({ ...payloadDetails, description: e.target.value })
                                    if (e.target.value.length < 6) {
                                        setErrorCheck("All fields required")
                                        setDescriptionError("Length must be > 6")
                                    } else {
                                        setErrorCheck("")
                                        setDescriptionError("")
                                    }
                                }}
                                type='text'
                                placeholder="Enter payload description"
                                className="selfOrderModel__inputFiled__value__input"
                            /></div>
                        <div className='selfOrderModel__inputFiled__error'>{descriptionError}</div>
                    </div>
                    <div className="selfOrderModel__inputFiled" style={{ border: (checkWeightLimitFunction(payloadDetails.weight) || payloadWeightError !== "") ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                        <div className="selfOrderModel__inputFiled__label">Payload Weight<span style={{ color: "red" }}>*</span></div>
                        <div className="selfOrderModel__inputFiled__value">
                            <input
                                value={payloadDetails.weight}
                                onChange={(e) => {
                                    setPayloadDetails({ ...payloadDetails, weight: e.target.value });
                                    if (checkWeightLimitFunction(e.target.value)) {
                                        setErrorCheck("weight limit 2500");
                                        setPayloadWeightError("weight limit 2500")
                                    } else {
                                        setErrorCheck("");
                                        setPayloadWeightError("")
                                    }
                                }}
                                type="number"
                                placeholder="Enter payload"
                                className="selfOrderModel__inputFiled__value__input"
                            />g</div>
                        <div className='selfOrderModel__inputFiled__error'>{payloadWeightError}</div>
                    </div>
                    {/* </>} */}
                    {/* {recievedOn === "Shopify" && <div className="selfOrderModel__inputFiled">
                    <div className="selfOrderModel__inputFiled__label">Order Number<span style={{ color: "red" }}>*</span></div>
                    <div className="selfOrderModel__inputFiled__value">
                        <input
                            value={orderNumber}
                            onChange={(e) => setOrderNumber(e.target.value)}
                            type='number'
                            placeholder="Enter Order Number"
                            className="selfOrderModel__inputFiled__value__input"
                        /></div>
                </div>} */}
                </div>
                <div className="selfOrderModel__totalItems__locationCont2">
                    <Form.Group
                        controlId="delivery-type"
                        className="selfOrderModel__totalItems__locationCont2__delivery-type-group"
                    >
                        <Form.ControlLabel className="selfOrderModel__totalItems__locationCont2__delivery-type-label">
                            Delivery Type<span style={{ color: "red" }}>*</span>
                        </Form.ControlLabel>
                        <RadioGroup
                            inline
                            // name="controlled-radio-buttons-group"
                            defaultValue={deliveryType}
                            name="radio-group-inline"
                            value={deliveryType}
                            onChange={handleDeliveryRadioChange}
                            className="selfOrderModel__totalItems__locationCont2__delivery-type-radio-group"
                        >
                            <Radio value="Urgent"
                                className="selfOrderModel__totalItems__locationCont2__delivery-type-radio"
                            >
                                Urgent
                            </Radio>
                            <Radio value="Scheduled"
                                className="selfOrderModel__totalItems__locationCont2__delivery-type-radio"
                            >
                                Scheduled
                            </Radio>

                        </RadioGroup>
                        {deliveryType === "Scheduled" && <div className='selfOrderModel__totalItems__locationCont2__datePicker'>
                            <DatePicker
                                format="dd/MM/yyyy"
                                value={deliveryDate}
                                oneTap={true}
                                onChange={(date) => {
                                    if (date) {
                                        const updatedDate = dayjs(date).hour(18).minute(0).second(0).millisecond(0).toDate();
                                        setDeliveryDate(updatedDate);
                                    } else {
                                        setDeliveryDate(date);
                                    }
                                }}
                                className='selfOrderModel__totalItems__locationCont2__datePicker__calendar'
                                placement="topStart"
                                // shouldDisableDate={(date) => {
                                //     const availableDates = availableOrderSlots.map(slot => dayjs(slot).startOf('day').toString());
                                //     const selectedDate = dayjs(date).startOf('day').toString();
                                //     return !availableDates.includes(selectedDate);
                                // }}
                                shouldDisableDate={(date) => {
                                    const availableDates = availableOrderSlots.map(slot => dayjs(slot).startOf('day'));
                                    const selectedDate = dayjs(date).startOf('day');
                                    const today = dayjs().startOf('day');

                                    return selectedDate.isSame(today) || !availableDates.some(availableDate => availableDate.isSame(selectedDate));
                                }}
                            />
                        </div>
                        }
                    </Form.Group>
                    <div className='selfOrderModel__totalItems__locationCont2__error'>{deliveryTypeError}</div>
                </div>
                {deliveryType === "Urgent" && <div
                    className="selfOrderModel__selectField"
                    style={{
                        border:
                            urgentReasonError !== "" && urgentReason === "Select"
                                ? "2px solid red"
                                : "2px solid var(--primaryTextColor)"
                    }}
                >
                    <div className="selfOrderModel__selectField__label">
                        Reason<span style={{ color: "red" }}>*</span>
                    </div>

                    <div className="selfOrderModel__selectField__value">
                        <select
                            value={urgentReason}
                            onChange={(e) => {
                                const selectedValue = e.target.value
                                if (selectedValue === "Select") {
                                    setUrgentReasonError("Please Select Destination Location");
                                    setUrgentReason("Select");
                                } else {
                                    setUrgentReasonError("");
                                    setUrgentReason(selectedValue)
                                }
                            }}
                            className="selfOrderModel__selectField__value__dropDown"
                        >
                            <option value="Select">Select</option>
                            {urgentReasonOption.map((option) => {
                                return (
                                    <option key={option.label} value={option.label}>
                                        {option.label}
                                    </option>
                                );
                            })}
                        </select>
                    </div>

                    <div className="selfOrderModel__selectField__error">
                        {urgentReasonError}
                    </div>
                </div>}
                <div className='selfOrderModel__applyItems'>

                    <div > <button className="selfOrderModel__applyItems__placeOrder"
                        onClick={recievedOn === "TecheagleOMS" ? handleSelfOrderDetails : handlePlaceOrderClick}>
                        Place Order</button>
                    </div>

                </div>
                {/* <div > <button className="selfOrderModel__close"
                onClick={handleSelfOrderModelClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>

            </button>
            </div> */}
                {/* <p style={{ visibility: errorCheck !== "" ? "visible" : "hidden" }} className='selfOrderModel__error'>{errorCheck ? errorCheck : "All fields required"}</p> */}
            </div>
        </div>
    )
}

export default SelfOrderModel;





// :
// <>
//     <div className="selfOrderModel__inputFiled" style={{ border: (sourceLocationError !== "" && sourceData.name.length < 3) ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
//         <div className="selfOrderModel__inputFiled__label">Source Location Name<span style={{ color: "red" }}>*</span></div>
//         <div className="selfOrderModel__inputFiled__value">
//             <input
//                 value={sourceData.name}
//                 onChange={(e) => {
//                     if (e.target.value.length < 3) {
//                         setErrorCheck("All fields required")
//                         setSourceLocationError("Length must be > 3");
//                     } else {
//                         setErrorCheck("")
//                         setSourceLocationError("");
//                     }
//                     setSourceData({ ...sourceData, name: e.target.value })
//                 }}
//                 type="text"
//                 placeholder="Enter Source Location Name"
//                 className="selfOrderModel__inputFiled__value__input"
//             /></div>
//         <div className='selfOrderModel__inputFiled__error'>{sourceLocationError}</div>
//     </div>
//     <div className="selfOrderModel__inputFiled" style={{ border: (destinationLocationError !== "" && destinationData.name.length < 3) ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
//         <div className="selfOrderModel__inputFiled__label" >Destination Location Name <span style={{ color: "red" }}>*</span></div>
//         <div className="selfOrderModel__inputFiled__value">
//             <input
//                 value={destinationData.name}
//                 onChange={(e) => {
//                     if (e.target.value.length < 3) {
//                         setErrorCheck("All fields required")
//                         setDestinationLocationError("Length must be > 3");
//                     } else {
//                         setErrorCheck("")
//                         setDestinationLocationError("");
//                     }
//                     setDestinationData({ ...destinationData, name: e.target.value })
//                 }}
//                 type="text"
//                 placeholder="Enter Destination Location Name"
//                 className="selfOrderModel__inputFiled__value__input"
//             /></div>
//         <div className='selfOrderModel__inputFiled__error'>{destinationLocationError}</div>
//     </div>

//     <div className="selfOrderModel__inputFiled">
//         <div className="selfOrderModel__inputFiled__label">Source Longitude</div>
//         <div className="selfOrderModel__inputFiled__value">
//             <input
//                 value={sourceData.long}
//                 type='number'
//                 onChange={(e) => setSourceData({ ...sourceData, long: e.target.value })}
//                 placeholder="Enter Source Longitude"
//                 className="selfOrderModel__inputFiled__value__input"
//             /></div>
//     </div>
//     <div className="selfOrderModel__inputFiled">
//         <div className="selfOrderModel__inputFiled__label">Destination Longitude</div>
//         <div className="selfOrderModel__inputFiled__value">
//             <input
//                 type='number'
//                 value={destinationData.long}
//                 onChange={(e) => setDestinationData({ ...destinationData, long: e.target.value })}
//                 placeholder="Enter Destination Longitude"
//                 className="selfOrderModel__inputFiled__value__input"
//             /></div>
//     </div>
//     <div className="selfOrderModel__inputFiled">
//         <div className="selfOrderModel__inputFiled__label">Source Latitude</div>
//         <div className="selfOrderModel__inputFiled__value">
//             <input
//                 type='number'
//                 value={sourceData.lat}
//                 onChange={(e) => setSourceData({ ...sourceData, lat: e.target.value })}
//                 placeholder="Enter Source Latitude"
//                 className="selfOrderModel__inputFiled__value__input"
//             /></div>
//     </div>
//     <div className="selfOrderModel__inputFiled">
//         <div className="selfOrderModel__inputFiled__label">Destination Latitude </div>
//         <div className="selfOrderModel__inputFiled__value">
//             <input
//                 type='number'
//                 value={destinationData.lat}
//                 onChange={(e) => setDestinationData({ ...destinationData, lat: e.target.value })}
//                 placeholder="Enter Destination Latitude "
//                 className="selfOrderModel__inputFiled__value__input"
//             /></div>
//     </div>
// </>}