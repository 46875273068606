import axios from "axios";
import { clearskyIP } from "../components/configuration";

export async function fetchOrderDeliverySlots(userCookie, setAvailableOrderSlots, handleNotifyAlert) {
    // const available = ["2024-12-27T06:30:00.000Z", "2024-12-28T00:30:00.000Z","2024-12-29T00:30:00.000Z", "2024-12-30T00:30:00.000Z"]
    axios({
        url: clearskyIP + "/book/slots-available/user",
        method: "GET",
        headers: {
            authorization: userCookie.token,
        },
    }).then((resp) => {

        console.log("clearsky", resp.data);
        setAvailableOrderSlots(resp.data.data.availableDays);
        // setAvailableOrderSlots(available);
    })
        .catch((err) => {
            console.log("err", err)
            if (err.response.data.message) {
                // window.alert(err.response.data.message);
                handleNotifyAlert("notify", err.response.data.message, "failed", () => { })
            }
            else handleNotifyAlert("notify", "some error occurered", "failed", () => { });
            //console.log(err);
        });
}