import React, { useState, useEffect, useRef } from "react";
import './CustomDropDown.scss'

const CustomDropDown = ({
  selectedItem,
  setSelectedItem,
  label,
  categories, BagList
}) => {
  const [visibleCategories, setVisibleCategories] = useState([])
  const [hiddenCategories, setHiddenCategories] = useState([])
  const [headerWidth, setHeaderWidth] = useState(window.innerWidth);
  const containerRef = useRef();

  useEffect(() => {
    const handelSize = () => {
      if(!containerRef.current) return;
      const containerWidth = containerRef.current.offsetWidth;
      let totalWidth = 0;
      const visible = []
      const hidden = []
      for (let category of categories){
        const itemWidth = getTextWidth(category, "16px Arial") + 10;
        if(totalWidth + itemWidth <= containerWidth - 80){
          visible.push(category);
          totalWidth += itemWidth;
        } else {
          hidden.push(category);
        }
      }
      setVisibleCategories(visible)
      setHiddenCategories(hidden)
    };
    handelSize()
    window.addEventListener('resize', handelSize);
    return () => window.removeEventListener('resize', handelSize)

  }, [categories, BagList])


  const getTextWidth = (text, font) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    return context.measureText(text).width;
  };

  // const adjustCategories = () => {
  //   const maxVisibleCategories = Math.floor(headerWidth / 140);
  //   setVisibleCategories(categories.slice(0, maxVisibleCategories));
  //   setHiddenCategories(categories.slice(maxVisibleCategories));
  // };

  return (
    <div className="CustomDropDown-container" ref={containerRef}>
      <div className="CustomDropDown-container__categoryList">
        {visibleCategories.map((category, index) => {
          return <div key={index + 1} className={`${selectedItem === category.name ?"CustomDropDown-container__categoryList__visibleListActive": "CustomDropDown-container__categoryList__visibleList" }`} onClick={() => { setSelectedItem(category.name) }}>
            {category.name}
          </div>
        })}
        {hiddenCategories.length > 0 ? 
        <select className="CustomDropDown-container__categoryList__more"
        value={selectedItem}
        style={{width: selectedItem === ""? "7%": "12%"}}
        onChange={(e)=>{
          if(e.target.value !== "More"){
            setSelectedItem(e.target.value)
          }else {
            setSelectedItem("")
          }
        }} >
          <option key={"More"} value={"More"}
            className="CustomDropDown-container__categoryList__more__dropdown-container"
          >More</option>

          {hiddenCategories.map((category, i) => {
            return <option key={i + 1} value={category.name}
              className="CustomDropDown-container__categoryList__more__dropdown-container"
            >
              {category.name}
            </option>
          })}
        </select>
        :null}
      </div>
      {/* <select
        className="category-dropdown"
        value={selectedItem}
        onChange={(e) => setSelectedItem(e.target.value)}
      >
        <option value="">{label}</option>
        {categories.map((item) => (
          <option key={item.id} value={item.name}>
            {item.name}
          </option>
        ))}
      </select> */}
    </div>
  );
};

export default CustomDropDown;
