import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "./HomePage.scss";
import Spinner from "react-bootstrap/Spinner";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomAlertBox from "./CustomAlert/CustomAlertBox";
import { useLocation, useNavigate } from "react-router-dom";
import InfoIndicatorBox from "./InfoIndicatorBox/InfoIndicatorBox";
import io from "socket.io-client";
import CategoryListItem from "./CategoryListItem/CategoryListItem";
import InventoryShowSubContent from "./InventoryShowSubContent/InventoryShowSubContent";
import styled from "styled-components";
import UserDropDown from "./UserDropDown/UserDropDown";
import OrderListSubContent from "./OrdersListSubContent/OrdersListSubContent";
import BagListSubContent from "./BagListSubContent/BagListSubContent";
import OrdersListSubContent from "./OrdersListSubContent/OrdersListSubContent";
import SpecificOrderSubContent from "./SpecificOrderSubContent/SpecificOrderSubContent";
import InventoryAdd from "./InventoryAddFrom/InventoryAddFormPage";
import CustomNotification from "./CustomNotification/CustomNotification";
import LoadingScreen from "./LoadingScreen/LoadingScreen";
import InventoryForm from "./InventoryForm/inventory";
import { backEndIP } from "./configuration";
import axios from "axios";
import InputForm from "./CustomInputField/inputComponent";
import ManageInventory from "./ManageInventory/ManageInventory";
import { BrowserView, isBrowser, isDesktop, isMobile, MobileView } from "react-device-detect";
import MyProfile from "./UserProfile/MyProfile";
import UserHelpManual from "./HelpManual/HelpManual";
import HelpManual from "./HelpManual/HelpManual";
import Dashboard from "../Pages/Dashboard/Dashboard";
import LeftPanelHomePage from "./Navigation/LeftPanel/LeftPanelHomePage";
import HomeContent from "./HomeContent/HomeContent";
import ShopifySubComponent from "./shopify/ShopifySubComponent";
import OrderModel from "./OrderModel/OrderModel";
import OrdersTabel from "./ViewAllOrders/viewAllOrders";
import HeaderBar from "./Navigation/Navebar/HeaderBar";
import CreateOrder from "./CreateOrder/CreateOrder";
import SideDrawer from "./SidebarDrawer/SideDrawer";
import { DrawerContext } from "../context/DrawerContext";
import OrderCreateSelect from "./OrderCreateSelect/OrderCreateSelect";
import HelpForm from "./HelpForm/HelpForm";
import ConfirmOrderDelivery from "./ConfirmOrderDeliveryPopUP/ConfirmOrderDeliver";

// const socket = io("https://ws5002.dineshborse.in", { transports: ["websocket", "polling"] });

const BagButtonDiv = styled.button`
  width: calc(101px * var(--widthmultiplier));
  height: calc(45px * var(--heightmultiplier));
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primaryColor);
  border-radius: calc(
    10px * (var(--heightmultiplier) + var(--widthmultiplier)) / 2
  );
  border:none;
  padding: calc(0px * var(--heightmultiplier))
    calc(18px * var(--widthmultiplier));
  &:hover {
    cursor: pointer;
  }
`;

const HomePage = ({ userCookie, setuserCookie, removeCookie, themeGlobal, userName, navSubContent }) => {
  const navigate = useNavigate();
  let urllocation = useLocation();
  const { openDrawer, setOpenDrawer, placement, setPlacement } = useContext(DrawerContext);
  let orderNo = 0;
  if (navSubContent[1] === "SpecficOrder") {
    orderNo = urllocation.pathname.split("/")
    orderNo = orderNo[orderNo.length - 1]
  }
  const [BagList, setBagList] = useState([]);
  const [cartQuantityUpdated, setCartQuantityUpdated] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const [userIconClicked, setUserIconClicked] = useState(false);
  const [userPermission, setUserPermission] = useState({})
  const [myLocation, setMyLocation] = useState({})
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedTab, setSelectedTab] = useState(navSubContent[1])
  const [selectedSubContent, setSelectedSubContent] = useState(navSubContent[1]);
  const [currentOrderNo, setCurrentOrderNo] = useState(orderNo);
  const [notificationVisibility, setNotificationVisibility] = useState(false);
  const [alertVisibility, setAlertVisibility] = useState(false);
  const [userHubLocation, setUserHubLocation] = useState("")
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [messageToDisplay, setMessageToDisplay] = useState({
    type: "notify",
    message: "msg",
    msgtype: "success",
    func: () => { },
  });
  const [orderDelvryConfReq, setOrderDelvryConfReq] = useState([])
  const [loadingVisibility, SetLoadingVisibility] = useState(false);
  const [categories, setCategories] = useState([]);
  const [customerDetailsPopup, setCustomerDetailsPopup] = useState(false)
  const permission = userCookie.permission
  //console.log("SetUserCookie", setuserCookie)
  // console.log("UserCookie", userCookie)
  // const handleOpen = (key) => {
  //   setOpenDrawer(true);
  //   setPlacement(key);
  // };
  useEffect(() => {
    if (userCookie.token === "" || !userCookie.token) {
      navigate("/");
      return;
    }
    if (userCookie.token) {
      axios({
        url: backEndIP + "/user/verifytoken",
        // url: backEndIP + "/user/login",
        method: "GET",
        headers: {
          authorization: userCookie.token
        },
      }).then(async (verifytoken) => {
        // console.log("verifytoken=>", verifytoken.data)
        await setuserCookie("token", verifytoken.data.token, { path: "/" })
        await setuserCookie("username", verifytoken.data.userName, { path: "/" })
        await setuserCookie("useremail", verifytoken.data.userEmail, { path: "/" })
        await setuserCookie("permission", verifytoken.data.permissionStatus, { path: "/" })
        setUserPermission(verifytoken.data.permissionStatus)
        setMyLocation(verifytoken.data.userLocation)
        setUserHubLocation(verifytoken.data.hubLocation)
        // console.log(loginData.data.hubLocation)

      }).catch((err) => {
        // window.alert("login again")
        //console.log(err);
        removeCookie("token");
        removeCookie("username");
        removeCookie("useremail");
        removeCookie("permission");
      })

    }
    else {
      navigate("/")
    }

  }, [])


  useEffect(() => {
    //console.log(urllocation, navSubContent[0])
    // if(urllocation.pathname!==navSubContent[0]){
    // navigate(urllocation.pathname)

    setSelectedSubContent(navSubContent[1])
    // }
  }, [urllocation.pathname]);

  useEffect(() => {
    if (userCookie.token === "" || !userCookie.token) {
      navigate("/");
      return;
    }
    if (userCookie.token) {
      SetLoadingVisibility(true);
      axios({
        url: backEndIP + "/cart-add/get-cart-items",
        method: "GET",
        headers: {
          authorization: userCookie.token,
        },
        // data: { id: product._id , quantity:1 }
      })
        .then((resp) => {
          SetLoadingVisibility(false);
          //console.log(resp.data);
          setBagList(resp.data.cart);
          setCartQuantityUpdated(true);
        })
        .catch((err) => {
          SetLoadingVisibility(false);
          if (err.response.data.message) window.alert(err.response.data.message);
          else window.alert("some error occurered");
          // console.log(err);
        });
    }
    if (userCookie.token) {
      SetLoadingVisibility(true);
      axios({
        url: backEndIP + "/order/get-all-location",
        method: "GET",
        headers: {
          authorization: userCookie.token,
        },
        // data: { id: product._id , quantity:1 }
      })
        .then((resp) => {
          SetLoadingVisibility(false);
          // console.log(resp.data.data);
          setAllLocations(resp.data.data);
          // SetDestLocation(allLocations[1]);
        })
        .catch((err) => {
          SetLoadingVisibility(false);
          if (err.response.data.message) window.alert(err.response.data.message);
          else window.alert("some error occurred");
          //console.log(err);
        });
    }
    // setBagList(sampleBagList)
  }, []);
  useEffect(() => {
    if (userCookie.token) {
      axios({
        url: backEndIP + "/order/get-delivered-order",
        // url: backEndIP + "/user/login",
        method: "GET",
        headers: {
          authorization: userCookie.token
        },
      }).then(async (respOrders) => {
        console.log("respOrders", respOrders.data.data)
        setOrderDelvryConfReq(respOrders.data.data)
        // navigate('/all-categories')

      }).catch((err) => {
        // window.alert("login again")
        console.log(err);
      })
    }
  }, [userCookie.token])

  useEffect(() => {
    //  console.log(userCookie.token)
    if (userCookie.token === "" || !userCookie.token) {
      navigate("/");
    } else {
      axios({
        url: backEndIP + "/category/get_category",
        method: "GET",
        headers: {
          authorization: userCookie.token,
        },
      }).then((data) => {
        //console.log(data.data.data[0]);
        setCategories(data.data.data);
        // setCategory(data.data.data[0].name)
      });
    }

  }, [userCookie.token]);


  // useEffect(() => {
  //     socket.on('connect', () => {
  //         setIsSocketConnected(true);
  //         socket.emit("upgrade")
  //     });

  //     socket.on('disconnect', () => {
  //         setIsSocketConnected(false);
  //     });

  //     socket.on('pong', () => {
  //         setLastPong(new Date().toISOString());
  //     });

  //     // socket.on()

  //     return () => {
  //         socket.off('connect');
  //         socket.off('disconnect');
  //         socket.off('pong');
  //     };
  // }, []);

  const navlinks = {
    "My Orders": "/my-orders",
    "Manage Orders": "/manage-orders",
    "Home": "/home",
    "Cart List": "/cart-list",
    "SpecficOrder": `/order/${currentOrderNo}`,
    "Manage Inventory": "/manage-inventory",
    "My Profile": "/myProfile",
    "Help": "/help",
    "Dashboard": "/userDashboard",
    "Help": "/help",
    "Shopify": '/shopify',
    "Dashboard": "/dashboard",
    "Create Order": "/create-order",
    "All Product": "/all-product",
    "My Profile": "/my-profile"
  }
  const reverseNavlinks = {
    "/my-orders": "My Orders",
    "/manage-orders": "Manage Orders",
    "/home": "Home",
    "/cart-list": "Cart List",
    // "SpecficOrder": `/order/${currentOrderNo}`,
    "/manage-inventory": "Manage Inventory",
    "/myProfile": "My Profile",
    "/help": "Help",
    "/userDashboard": "Dashboard",
    "/help": "Help",
    '/shopify': "Shopify",
    "/dashboard": "Dashboard",
    "/create-order": "Create Order",
    "/all-product": "All Product",
    "/my-profile": "My Profile"
  }

  useEffect(() => {
    if (userCookie.token === "" || !userCookie.token) {
      navigate("/");
      // setSelectedTab(reverseNavlinks[window.location.pathname])
      return;
    } else {
      console.log("window.history=>", window.location.pathname)
      if (window.location.pathname === "/all-product") {
        // setSelectedSubContent(reverseNavlinks[window.location.pathname])
        setSelectedTab("Create Order")
      } else if (window.location.pathname === `/order/${orderNo}`) {
        if(userCookie.permission === "Granted"){
          setSelectedTab("Manage Orders")
        } else {
          setSelectedTab("My Orders")
        }
      } else {
        navigate(navlinks[selectedSubContent])
        // console.log("window.history=>", window.location.pathname)
        // setSelectedSubContent(reverseNavlinks[window.location.pathname])
        setSelectedTab(reverseNavlinks[window.location.pathname])
      }
    }
    // setSelectedSubContent(reverseNavlinks[window.location.pathname])
  }, [selectedSubContent])

  const handleSubContentSelect = (optionName) => {
    if (optionName !== "Logout" && optionName !== "") {
      setSelectedCategory("");
      setSelectedSubContent(optionName);
      // navigate(navlinks[optionName])

    }
    console.log(optionName);
    setUserIconClicked(false);

    if (optionName === "Logout") {
      removeCookie("token");
      removeCookie("username");
      removeCookie("useremail");
      removeCookie("permission");
      // setuserCookie("token", "", { path: "/" });
      // setuserCookie("username",  "", { path: "/" })
      // setuserCookie("useremail",  "", { path: "/" })
      // setuserCookie("permission",  "", { path: "/" })
    }
  };
  useEffect(() => {
    if (selectedSubContent !== "Create Order") {
      setCustomerDetailsPopup(false)
    }
  }, [selectedSubContent]);

  const handleUpdateAlertNottifyMsg = (type, message, msgtype, func) => {
    setMessageToDisplay({
      type: type,
      message: message,
      msgtype: msgtype,
      func: () => {
        SetLoadingVisibility(false);
        func();
      },
    });
    if (type === "notify") {
      setNotificationVisibility(true);
    } else if (type === "alert") {
      setNotificationVisibility(true);
    }
  };
  const handelBackNavigation = () => {
    console.log("History", window.history.state?.idx)
    if (window.history.state?.idx > 0) {
      if (window.history.state?.idx > 0) {
        navigate(-1);
      }
    }
  }
  // console.log("UserCookie", userCookie)
  const handleDeleteCartItem = (index) => {
    SetLoadingVisibility(true);
    axios({
      url: backEndIP + "/cart-add/del-item-cart",
      method: "DELETE",
      headers: {
        authorization: userCookie.token,
      },
      data: {
        index: index
      }
    })
      .then((resp) => {
        SetLoadingVisibility(false);
        //console.log(resp.data);
        setBagList(resp.data.cart);
        handleUpdateAlertNottifyMsg("notify", "Item Deleted Successfully", "success", () => { })
      })
      .catch((err) => {
        SetLoadingVisibility(false);
        if (err.response.data.message)
          // window.alert(err.response.data.message);
          handleUpdateAlertNottifyMsg("notify", err.response.data.message, "failed", () => { })
        else window.alert("some error occurred");
        // console.log(err);
      });
  }

  const handleUpdateItemQuantityInBag = (index, quantity) => {
    setCartQuantityUpdated(false);
    BagList[index].quantity = quantity;
    setTotalPrice(calculateTotalPrice());
    setTotalWeight(calculateTotalWeight());
    console.log(BagList[index].quantity);
  };
  const calculateTotalWeight = () => {
    if (BagList.length) {
      let totalWeight = 0;
      BagList.forEach((item) => {
        totalWeight += item.quantity * item.weight;
      });
      return totalWeight;
    }
    return 0;
  };

  const calculateTotalPrice = () => {
    if (BagList.length) {
      let totalWeight = 0;
      BagList.forEach((item) => {
        totalWeight += item.quantity * item.price;
      });
      return totalWeight;
    }
    return 0;
  };

  // console.log("BagList", BagList)
  // console.log(" props.orderDlvryConfReq", orderDelvryConfReq)

  return (
    <>
      {<div className="home-page-container">
        <div className="home-sidebar">
          <LeftPanelHomePage
            userCookie={userCookie}
            permission={permission}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            subContent={setSelectedSubContent}
            themeGlobal={themeGlobal} />
          {/* <LeftPanelHomePage userCookie={userCookie} selectedTab={selectedTab} setSelectedTab={setSelectedTab} subContent={setSelectedSubContent} /> */}

          {/* <div className="home-sidebar__logodiv">
            <div>
              <img
                className="home-sidebar__logodiv__logoimg"
                src={backEndIP + "/" + themeGlobal.nameLogo}
                alt="logo"
              />
            </div>
            <div className="home-sidebar__logodiv__back" onClick={handelBackNavigation}>
              <svg className="home-sidebar__logodiv__back__Icon" width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 13L18 7L11 1" stroke="#878B8F" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M1 6.25C0.585787 6.25 0.25 6.58578 0.25 7C0.25 7.41421 0.585787 7.75 1 7.75L1 6.25ZM18 6.25L1 6.25L1 7.75L18 7.75L18 6.25Z" fill="#878B8F" />
              </svg>
            </div>
          </div> */}
          {/* <div className="home-sidebar__logodiv-border"></div> */}
          {/* <div className="home-sidebar__Categories-container">
            <div
              className="home-sidebar__Categories-container__Title"
              onClick={() => {
                setSelectedCategory("");
                setSelectedSubContent("");
              }}
            >
              Categories
            </div>
            {categories.map((cat) => {
              return (
                <CategoryListItem
                  categoryName={cat.name}
                  key={`category${cat.name}`}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  setSelectedSubContent={setSelectedSubContent}
                />
              );
            })}
          </div> */}
          {/* <img className='home-main-map__search-container__phc-logo'
                    style={{
                        height: "calc(200px * var(--heightmultiplier))",
                        width: "calc(200px * var(--heightmultiplier))",
                        marginTop: "calc(600px * var(--heightmultiplier))"
                    }}
                    src='PHC-logo.svg' alt="phc logo" /> */}
        </div>

        <div className="home-main-map">
          <div className="home-main-map__topInfobar">
            <HeaderBar
              userName={userName}
              userCookie={userCookie}
              BagList={BagList}
              setSelectedSubContent={setSelectedSubContent}
              setUserIconClicked={setUserIconClicked}
              themeGlobal={themeGlobal}
              setOpenDrawer={setOpenDrawer}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab} />
          </div>
          <div className="home-main-map__content">
            {selectedSubContent === "Dashboard" && (
              <Dashboard
                userCookie={userCookie}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                setSelectedSubContent={setSelectedSubContent} />)}
            {selectedSubContent === "Home" && (
              <HomeContent
                SetLoadingVisibility={SetLoadingVisibility}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                userCookie={userCookie}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
                BagList={BagList}
                setBagList={setBagList}
                userHubLocation={userHubLocation}
                setSelectedSubContent={setSelectedSubContent}
                allLocations={allLocations}
                myLocation={myLocation}
                handleDeleteCartItem={handleDeleteCartItem}
                setContentOption={handleSubContentSelect}
                setOrderNo={setCurrentOrderNo}
                setSelectedTab={setSelectedTab}
                totalWeight={totalWeight}
                setTotalPrice={setTotalPrice}
                setTotalWeight={setTotalWeight}
                calculateTotalPrice={calculateTotalPrice}
                calculateTotalWeight={calculateTotalWeight}
              />
            )}
            {selectedSubContent === "Create Order" &&
              <OrderCreateSelect
                userCookie={userCookie}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                setSelectedSubContent={setSelectedSubContent}
                selectedSubContent={selectedSubContent}
                userHubLocation={userHubLocation}
                myLocation={myLocation}
                allLocations={allLocations}
                setSelectedCategory={setSelectedCategory}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
                SetLoadingVisibility={SetLoadingVisibility}
              // setCustomerDetailsPopup={setCustomerDetailsPopup}
              // customerDetailsPopup={customerDetailsPopup}
              />}
            {selectedSubContent === "Manage Orders" && (
              <OrdersTabel
                userCookie={userCookie}
                SetLoadingVisibility={SetLoadingVisibility}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
                setSelectedSubContent={setSelectedSubContent}
                setContentOption={handleSubContentSelect}
                setOrderNo={setCurrentOrderNo}
                setSelectedTab={setSelectedTab}
              />
            )}
            {selectedSubContent === "My Profile" && (
              <MyProfile
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
                setContentOption={handleSubContentSelect}
                currentOrderNo={currentOrderNo}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
              />
            )}
            {/* {customerDetailsPopup && selectedSubContent === "Create Order" && <div className="orderModel_container">
            <OrderModel
                userHubLocation={userHubLocation}
                myLocation={myLocation}
                allLocations={allLocations}
                setCustomerDetailsPopup={setCustomerDetailsPopup}
                deliveryType
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
              />
            </div>} */}
            {customerDetailsPopup ? <div className="orderModel_container">
              <OrderModel
                userHubLocation={userHubLocation}
                myLocation={myLocation}
                allLocations={allLocations}
                setCustomerDetailsPopup={setCustomerDetailsPopup}
                deliveryType
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
              />
            </div> : null}
            {selectedSubContent === "All Product" && (
              <CreateOrder
                SetLoadingVisibility={SetLoadingVisibility}
                selectedCategory={selectedCategory}
                userPermission={userPermission}
                userHubLocation={userHubLocation}
                allLocations={allLocations}
                cartQuantityUpdated={cartQuantityUpdated}
                userCookie={userCookie}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
                BagList={BagList}
                setBagList={setBagList}
                setCustomerDetailsPopup={setCustomerDetailsPopup}
                customerDetailsPopup={customerDetailsPopup}
                setSelectedCategory={setSelectedCategory}
                handleDeleteCartItem={handleDeleteCartItem}
                setContentOption={handleSubContentSelect}
                setOrderNo={setCurrentOrderNo}
                setSelectedTab={setSelectedTab}
                setSelectedSubContent={setSelectedSubContent}
                totalWeight={totalWeight}
                setTotalPrice={setTotalPrice}
                setTotalWeight={setTotalWeight}
                calculateTotalPrice={calculateTotalPrice}
                calculateTotalWeight={calculateTotalWeight}
              />
            )}
            {selectedSubContent === "My Orders" && (
              <OrderListSubContent
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
                setContentOption={handleSubContentSelect}
                setOrderNo={setCurrentOrderNo}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
              />
            )}
            {selectedSubContent === "SpecficOrder" && (
              <SpecificOrderSubContent
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
                userPermission={userPermission}
                setContentOption={handleSubContentSelect}
                currentOrderNo={currentOrderNo}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
              />
            )}
            {selectedSubContent === "Cart List" && (
              <BagListSubContent
                handleDeleteCartItem={handleDeleteCartItem}
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
                userPermission={userPermission}
                userHubLocation={userHubLocation}
                myLocation={myLocation}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
                handleUpdateItemQuantityInBag={handleUpdateItemQuantityInBag}
                BagList={BagList}
                totalWeight={totalWeight}
                setBagList={setBagList}
                setTotalPrice={setTotalPrice}
                setTotalWeight={setTotalWeight}
                calculateTotalPrice={calculateTotalPrice}
                calculateTotalWeight={calculateTotalWeight}
                allLocations={allLocations}
                setCartQuantityUpdated={setCartQuantityUpdated}
                cartQuantityUpdated={cartQuantityUpdated}
                setSelectedSubContent={setSelectedSubContent}
                setContentOption={handleSubContentSelect}
                setOrderNo={setCurrentOrderNo}
                setSelectedTab={setSelectedTab}
              />
            )}
            {/* {selectedSubContent === "Shopify" && (
              <ShopifySubComponent
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
                userPermission={userPermission}
                userHubLocation={userHubLocation}
                myLocation={myLocation}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
                allLocations={allLocations}
                cartQuantityUpdated={cartQuantityUpdated}
                handleDeleteCartItem={handleDeleteCartItem}
                handleUpdateItemQuantityInBag={handleUpdateItemQuantityInBag}
                totalPrice={totalPrice}
                totalWeight={totalWeight}
                setTotalPrice={setTotalPrice}
                setTotalWeight={setTotalWeight}
                calculateTotalPrice={calculateTotalPrice}
                calculateTotalWeight={calculateTotalWeight}
              />
            )}
            {/* {selectedSubContent === "Add Inventory" && (
              <InventoryForm
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
              />
            )} */}
            {selectedSubContent === "Manage Inventory" && (
              <ManageInventory
                SetCategories={setCategories}
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
              />
            )}
            {selectedSubContent === "My Profile" && (
              <MyProfile
                SetLoadingVisibility={SetLoadingVisibility}
                userCookie={userCookie}
                setContentOption={handleSubContentSelect}
                currentOrderNo={currentOrderNo}
                handleNotifyAlert={handleUpdateAlertNottifyMsg}
              />
            )}
            {selectedSubContent === "Help" && (
              <HelpForm 
              SetLoadingVisibility={SetLoadingVisibility}/>
              
              // <HelpManual
              //   SetCategories={setCategories}
              //   userPermission={userPermission}
              //   SetLoadingVisibility={SetLoadingVisibility}
              //   userCookie={userCookie}
              //   handleNotifyAlert={handleUpdateAlertNottifyMsg}
              // />
            )}
          </div>
          <div className="home-main-map__footer-container">
            <div className="home-main-map__footer-container__copyright">
              <img
                className="home-main-map__footer-container__copyright__company-logo"
                src={`${backEndIP}/${themeGlobal.EagleLogo}`}
              ></img>
              <div className="home-main-map__footer-container__copyright__title">
                Copyright 2024. TechEagle Innovations Pvt.Ltd.
              </div>
            </div>
          </div>
        </div>
        {userIconClicked && (
          <UserDropDown
            userPermission={userPermission}
            setOpenModal={setUserIconClicked}
            setContentOption={handleSubContentSelect}
          />
        )}
        {notificationVisibility && (
          <CustomNotification
            setOpenModal={setNotificationVisibility}
            messageToDisplay={messageToDisplay}
          />
        )}
        {openDrawer && <SideDrawer
          bagList={BagList}
          handleDeleteCartItem={handleDeleteCartItem}
          SetLoadingVisibility={SetLoadingVisibility}
          handleUpdateItemQuantityInBag={handleUpdateItemQuantityInBag}
          setSelectedSubContent={setSelectedSubContent}
          userHubLocation={userHubLocation}
          allLocations={allLocations}
          userCookie={userCookie}
          myLocation={myLocation}
        //  index={index}
        />
        }
        {loadingVisibility && <LoadingScreen />}
        {orderDelvryConfReq.length > 0 && userCookie.permission === "Not Grnated" &&<ConfirmOrderDelivery 
        orderMarkCnfrmDlvry={orderDelvryConfReq[0]} 
        SetLoadingVisibility={SetLoadingVisibility} 
        userCookie={userCookie} 
        setOrderDelvryConfReq={setOrderDelvryConfReq}
        handleNotifyAlert={handleUpdateAlertNottifyMsg}
        />}
      </div>}

    </>

  );
};

export default HomePage;
