import axios from "axios";
import { React, useEffect, useRef, useState } from "react";
import { backEndIP } from "../configuration";
// import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { update } from "../../redux/userSlice";
// import { PersonIcon } from '@mui/icons-material';

import "./Login.scss"
import CustomNotification from "../CustomNotification/CustomNotification";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
const Login = (props) => {

  // const user = useSelector((state)=>state.user);
  // const userDispatch = useDispatch();
  const { setuserCookie, userCookie, removeCookie, themeGlobal, setUserName } = props;
  const userEmailElementRef = useRef();
  const passwordElementRef = useRef();
  const errorMessageElementRef = useRef();
  const navigate = useNavigate();
  const userdetail = {
    name: "",
    email: "",
  }
  const [notificationVisibility, setNotificationVisibility] = useState(false);
  const [alertVisibility, setAlertVisibility] = useState(false);
  const [messageToDisplay, setMessageToDisplay] = useState({
    type: "notify",
    message: "msg",
    msgtype: "success",
    func: () => { },
  });
  const [loadingVisibility, setLoadingVisibility] = useState(false);

  useEffect(() => {
    if (userCookie.token) {
      axios({
        url: backEndIP + "/user/verifytoken",
        // url: backEndIP + "/user/login",
        method: "GET",
        headers: {
          authorization: userCookie.token
        },
      }).then(async (loginData) => {
        await setuserCookie("token", loginData.data.token, { path: "/" })
        await setuserCookie("username", loginData.data.userName, { path: "/" })
        await setuserCookie("useremail", loginData.data.userEmail, { path: "/" })
        await setuserCookie("permission", loginData.data.permissionStatus, { path: "/" })
        if (loginData.data.permissionStatus === "Granted") {
          navigate("/dashboard")
        } else {
          navigate("/home")
        }
        // navigate('/all-categories')

      }).catch((err) => {
        // window.alert("login again")
        //console.log(err);
        removeCookie("token");
        removeCookie("username");
        removeCookie("useremail");
        removeCookie("permission");
      })

    }

  }, [userCookie.token])

  // useEffect(()=>{
  //   if(userCookie.token){
  //     axios({
  //       url: backEndIP + "/order/get-delivered-order",
  //       // url: backEndIP + "/user/login",
  //       method: "GET",
  //       headers: {
  //         authorization: userCookie.token
  //       },
  //     }).then(async (respOrders) => {
  //       console.log("respOrders",respOrders.data)
  //     //  props.setOrderDlvryCnfReq()
  //       // navigate('/all-categories')

  //     }).catch((err) => {
  //       // window.alert("login again")
  //       //console.log(err);
  //     })
  //   }
  // }, [userCookie.token])

  const handleUpdateAlertNottifyMsg = (type, message, msgtype, func) => {
    setMessageToDisplay({
      type: type,
      message: message,
      msgtype: msgtype,
      func: () => {
        setLoadingVisibility(false);
        func();
      },
    });
    if (type === "notify") {
      setNotificationVisibility(true);
    } else if (type === "alert") {
      setNotificationVisibility(true);
    }
  };

  const handleLoginBtnClick = () => {
    let userEmail = userEmailElementRef.current.value;
    let password = passwordElementRef.current.value;
    // console.log(userEmail, password)
    setLoadingVisibility(true)
    if (userEmail.length >= 6 && password.length >= 6) {
      errorMessageElementRef.current.style.opacity = 0;
      axios({
        url: backEndIP + "/user/login",
        method: "POST",
        headers: {
        },
        data: { useremail: userEmail, password: password }
      }).then(async (loginData) => {
        // console.log("loginData=", loginData)

        // document.cookie = "testCookie=thisistext; path=/;";
        await setuserCookie("token", loginData.data.token, { path: "/" })
        await setuserCookie("username", loginData.data.userName, { path: "/" })
        await setuserCookie("useremail", loginData.data.userEmail, { path: "/" })
        await setuserCookie("permission", loginData.data.permissionStatus, { path: "/" })
        // console.log(userCookie)

        setUserName(loginData.data.userName)
        userdetail.name = loginData.data.userName;
        userdetail.email = loginData.data.userEmail;
        //userdetail.permissionStatus = loginData.data.permissionStatus;
        setUserName(loginData.data.userName);
        // console.log(userCookie.token)
        // userDispatch(update(userdetail))
        if (loginData.data.permissionStatus === "Granted") {
          navigate("/dashboard")
        } else {
          navigate("/home")
        }

      }).catch((err) => {
        console.log(err)
        setLoadingVisibility(false)
        handleUpdateAlertNottifyMsg("alert", `${err.response.data.message}`, "failed", () => { })
      })
    }
    else {
      if (userEmail.length < 6) {
        setLoadingVisibility(false);
        handleUpdateAlertNottifyMsg("notify", "User name length should be more the 5", "failed", () => { })
        errorMessageElementRef.current.innerHTML = "User name length should be more the 5"
        errorMessageElementRef.current.style.opacity = 1;
      }
      else {
        setLoadingVisibility(false);
        handleUpdateAlertNottifyMsg("notify", "password length should be more than or equal to 6", "failed", () => { })
        errorMessageElementRef.current.innerHTML = "password length should be more than or equal to 6"
        errorMessageElementRef.current.style.opacity = 1;
      }
    }
  }
  // console.log("userCookie", userCookie)

  return (
    <div className="login-page-body" style={{ height: "100vh", width: "100vw" }}>
      <div className="login-page-body__leftHalf">
        <div className="login-page-body__leftHalf__welcomeBox">
          <img className="login-page-body__leftHalf__welcomeBox__logo"
            src={themeGlobal.EagleLogo} alt="logo"
          >
          </img>
          <div className="login-page-body__leftHalf__welcomeBox__welcomeMsg">Welcome</div>
          <div className="login-page-body__leftHalf__welcomeBox__register-message">To continue Sign In</div>
        </div>
        <div className="login-page-body__leftHalf__login-container">
          <input ref={userEmailElementRef} className="login-page-body__leftHalf__login-container__input-email" 
          type={"email"} 
          placeholder={"User Email Id"}
          onKeyDown={(e) => {
            //console.log(e.key)
            if (e.key === "Enter") handleLoginBtnClick();
          }}
          ></input>
          <input ref={passwordElementRef} className="login-page-body__leftHalf__login-container__input-password"
          type={"password"} 
          placeholder={"Password"}
          onKeyDown={(e) => {
            //console.log(e.key)
            if (e.key === "Enter") handleLoginBtnClick();
          }}></input>
          <div className="login-page-body__leftHalf__login-container__forgotPassword"
            onClick={() => { navigate("/forgot-password") }}
          >Forgot password?</div>
          <div onClick={handleLoginBtnClick}
            className="login-page-body__leftHalf__login-container__btn-SignIn">Sign In</div>
          {/* <div
            className="login-page-body__leftHalf__login-container__register-msg1">
            Don’t have an account?
            <span
              className="login-page-body__leftHalf__login-container__register-msg2"
              onClick={() => { navigate("/register") }}
            >Create New Account
            </span>
          </div> */}
          <div ref={errorMessageElementRef}
            className="login-page-body__leftHalf__login-container__error-msg"
          >message</div>
        </div>
      </div>
      <div className="login-page-body__rightHalf">
        <img className="login-page-body__rightHalf__img"
          src="drone_in_ancient_cave_processed.png" alt="img"></img>
      </div>
      {notificationVisibility && (
        <CustomNotification
          setOpenModal={setNotificationVisibility}
          messageToDisplay={messageToDisplay}
        />
      )}
      {loadingVisibility && <LoadingScreen />}

    </div>
  )
}


export default Login;