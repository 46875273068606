import dayjs from "dayjs";
import React, { createContext, useState, useContext } from "react";

// Create the context
export const CustomerDetailContext = createContext();

// Create the provider
export const CustomerDetailProvider = ({ children }) => {
  const [customerData, setCustomerData] = useState({
    name: "",
    email: "",
    phoneNo: "",
  });
  const [destLocation, setDestLocation] = useState("Select");
  const [recievedOn, setRecievedOn] = useState("");
  const [sourceData, setSourceData] = useState({
    lat: "",
    long: "",
    name: "",
  });
  
  const [destinationData, setDestinationData] = useState({
    lat: "",
    long: "",
    name: "",
  });
  const [deliveryType, setDeliveryType] = useState("");
  const [orderDeliveryType, setOrderDeliveryType] = useState("Select");
  const [orderType, setOrderType] = useState("Self")
  const [deliveryDate, setDeliveryDate] = useState("");
  const [source_location, set_source_location] = useState("");
  const [destination_location, set_destination_location] = useState("");
  const [flightRequired, setFlightRequired] = useState(2)
  const [flightType, setFlightType] = useState("Forward-Return")
  const [payloadDetails, setPayloadDetails]=useState({
    type:"",
    description:"",
    weight:0
  })
   const [selectOrderView, setSelectOrderView] = useState("OMS");
    const [availableOrderSlots, setAvailableOrderSlots] = useState([]);
  const [urgentReason, setUrgentReason] = useState("Select")
  return (
    <CustomerDetailContext.Provider value={{payloadDetails, setPayloadDetails, source_location, set_source_location, destination_location, set_destination_location, deliveryDate, setDeliveryDate, orderType, setOrderType,deliveryType, setDeliveryType, orderDeliveryType, setOrderDeliveryType, sourceData, setSourceData, destinationData, setDestinationData, customerData, setCustomerData, destLocation,  recievedOn, setRecievedOn, setDestLocation, urgentReason, setUrgentReason, availableOrderSlots, setAvailableOrderSlots,selectOrderView, setSelectOrderView,
    flightRequired, setFlightRequired, flightType, setFlightType 
    }}>
      {children}
    </CustomerDetailContext.Provider>
  );
};