import React, { useState } from "react";
import CategoryAdd from "../Category/CategoryAdd";
import InventoryForm from "../InventoryForm/inventory";
import InventoryTabel from "../InventoryTable/InventoryTabel";
import OrdersTabel from "../ViewAllOrders/viewAllOrders";
import ExportButton from "../CsvJsonConverter/ExportButton";
import TemplateButton from "../CsvJsonConverter/TemplateButton";
import "./manageInventory.scss";
import { useContext } from "react";
import { ListItemsContext } from "../../context/inventoryData";
import ImportInventory from "../CsvJsonConverter/ImportInventory";
import ImportCategory from "../CsvJsonConverter/ImportCategory";
import BulkUpload from "../BulkUpload/BulkUpload";




const ManageInventory = ({
  SetCategories,
  userCookie,
  SetLoadingVisibility,
  handleNotifyAlert,
}) => {
  const [select, setSelect] = useState("Add Inventory");
  const { listItems } = useContext(ListItemsContext);

  return (
    <div className="InventoryManagementContainer">
      <div className="ManageInventory">
        <div className="ManageInventory__title">
          {/* <div></div> */}
          <div className="ManageInventory__title__bulkUpload" >
          <BulkUpload
            select={select}
            listItems={listItems}
            userCookie={userCookie}
            handleNotifyAlert={handleNotifyAlert} />
          </div>
          </div>

        

          <div className="ManageInventory__header">
            <button
              className={`ManageInventory__header__links ManageInventory__header__links${select==='Add Inventory'?"__active":""}`}
              onClick={() => setSelect("Add Inventory")}
            >
              Add Inventory
            </button>
            <button
              className={`ManageInventory__header__link1 ManageInventory__header__link1${select==='Inventory List'?"__active":""}`}
              onClick={() => setSelect("Inventory List")}
            >
              Inventory List
            </button>
            {/* <button
              className="ManageInventory__header__link1"
              onClick={() => setSelect("Order List")}
            >
              Order List
            </button> */}
            <button
              className={`ManageInventory__header__link ManageInventory__header__link${select==='Add Categories'?"__active":""}`}
              onClick={() => setSelect("Add Categories")}
            >
              Add Categories
            </button>
          </div>
      </div>






      {/* Dynamic Content Section */}
      <div className="ManageInventory__block">
        {select === "" && (
          <InventoryForm
            userCookie={userCookie}
            SetLoadingVisibility={SetLoadingVisibility}
            handleNotifyAlert={handleNotifyAlert}
          />
        )}
        {select === "Add Inventory" && (
          <InventoryForm
            userCookie={userCookie}
            SetLoadingVisibility={SetLoadingVisibility}
            handleNotifyAlert={handleNotifyAlert}
          />
        )}
        {select === "Inventory List" && (
          <InventoryTabel
            userCookie={userCookie}
            SetLoadingVisibility={SetLoadingVisibility}
            handleNotifyAlert={handleNotifyAlert}
          />
        )}
        {/* {select === "Order List" && (
          <OrdersTabel
            userCookie={userCookie}
            SetLoadingVisibility={SetLoadingVisibility}
            handleNotifyAlert={handleNotifyAlert}
          />
        )} */}
        {select === "Add Categories" && (
          <CategoryAdd
            SetCategories={SetCategories}
            userCookie={userCookie}
            SetLoadingVisibility={SetLoadingVisibility}
            handleNotifyAlert={handleNotifyAlert}
          />
        )}
      </div>
    </div>
  );
};

export default ManageInventory;
