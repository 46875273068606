import React, { useEffect, useRef, useState } from "react";
import "./HelpForm.scss";

const HelpForm = ({
    //   SetCategories,
    //   userPermission,
    //   userCookie,
      SetLoadingVisibility,
    //   handleNotifyAlert,
}) => {
    // const [isLoading, setIsLoading] = useState(true)
    useEffect(()=>{
        SetLoadingVisibility(true)
    }, [])

    return (
        <div className="HelpFormContainer">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfj7rMRUgGIu9h6XjBV_WjIetua2ri5Ori8TSgPI4wVegCjJQ/viewform?embedded=true" 
          title="Google Form"
          width="100%" 
          height="800px" 
          frameborder="0" 
          marginheight="0" 
          marginwidth="0"
          className="HelpFormContainer__google-form"
          onLoad={()=>SetLoadingVisibility(false)}
          />
        </div>
    );
};

export default HelpForm;
