import React, { useEffect, useState } from "react";
import "./MyProfile.scss";
import axios from "axios";
import { backEndIP } from "../configuration";
import ChangePasswordPopup from "../ChangePassword/ChangePasswordPopup";
import BasicDetails from "../BasicDetails/BasicDetails";
import AddressDetails from "../AddressDetails/AddressDetails";
import UpdateUserProfile from "../UpdateProfile/UpdateProfile";

const MyProfile = ({
    SetCategories,
    userCookie,
    SetLoadingVisibility,
    handleNotifyAlert,
}) => {
    const [myProfileData, setMyProfileData] = useState({})
    const [passWordChange, setPassWordChange] = useState(false)
    const [hubLocation , setHubLocation] = useState({})
    const [myLocation, setMyLocation]= useState({})
    const [editBasicDetails, setEditBasicDetails] = useState(false)
    const [updateSelf,setUpdateSelf] = useState(false)

    useEffect(() => {
        SetLoadingVisibility(true)
        axios({
            url: backEndIP + "/user/myProfile",
            method: "GET",
            headers: {
                authorization: userCookie.token,
            },
        }).then((resp) => {
            SetLoadingVisibility(false)
            //console.log(resp.data)
            setMyProfileData(resp.data.data)
            // setCategory(data.data.data[0].name)
        }).catch((err) => {
            //console.log(err)
            SetLoadingVisibility(false);
            handleNotifyAlert(
                "notify",
                `${err.response.data.message}`,
                "failed",
                () => { }
            );
        })
        // setUpdateSelf(prev=>!prev)
    }, [updateSelf]);


    useEffect(() => {
        SetLoadingVisibility(true)
        axios({
            url: backEndIP + `/hub/get_hubById`,
            method: "GET",
            headers: {
                authorization: userCookie.token,
            }
        }).then((resp) => {
            SetLoadingVisibility(false)
            // console.log(resp.data)
            setHubLocation(resp.data.data)
            // setCategory(data.data.data[0].name)
        }).catch((err) => {
           // console.log(err)
            SetLoadingVisibility(false);
            handleNotifyAlert(
                "notify",
                `${err.response.data.message}`,
                "failed",
                () => { }
            );
        })
        setUpdateSelf(prev=>!prev)
    }, []);
    useEffect(() => {
        if(myProfileData.delivery_location !== null){
        SetLoadingVisibility(true)
        axios({
            url: backEndIP + `/location/get_myLocation`,
            method: "GET",
            headers: {
                authorization: userCookie.token,
            }
        }).then((resp) => {
            SetLoadingVisibility(false)
            //console.log(resp.data)
            setMyLocation(resp.data.data)
            // setCategory(data.data.data[0].name)
        }).catch((err) => {
           // console.log(err)
            SetLoadingVisibility(false);
            handleNotifyAlert(
                "notify",
                `${err.response.data.message}`,
                "failed",
                () => { }
            );
        })
    }
    setUpdateSelf(prev=>!prev)
    }, []);

    // const locations = {
    //     "63932c7ab1cab628335ae969": "Jengjal",
    //     "63930f82865ec3abed90bc1b": "Mendipathar PHC",
    //     "639310eb865ec3abed90bc1f": "Pedaldoba PHC",
    //     "639311e2865ec3abed90bc23": "Nongalbibra PHC",
    //     "6393126c865ec3abed90bc27": "Shallang PHC",
    //     "639312ff865ec3abed90bc2b": "Samanda PHC",
    //     "63bd5b3ccafee0c35772f8f1": "Office",
    // }
   

    return (
        <div className="MyProfileDashBoard">
            {/* <div className="MyProfileDashBoard-conatiner">
                <div className="MyProfileDashBoard-conatiner__title">My Profile</div>
            </div> */}
            <div className="MyProfileDashBoard-SubConatiner">
                <div className="MyProfileDashBoard-SubConatiner__image">
                    <img className="MyProfileDashBoard-SubConatiner__image__proImg" src={`${backEndIP}/userProfile.svg.svg`} alt="" />
                    <div className="MyProfileDashBoard-SubConatiner__image__name">{myProfileData.userName}</div>
                </div>
                <div  className="MyProfileDashBoard-SubConatiner__detailsBlock">
               <BasicDetails userInfo={myProfileData} setPassWordChange={setPassWordChange} setEditBasicDetails={setEditBasicDetails} />
               <AddressDetails userLocation={hubLocation} title={"Hub Details"} label={"Hub Name"}/>
               {myProfileData.delivery_location !== null &&
               <AddressDetails userLocation={myLocation} title={"My Location"} label={"Location Name"}/>}
               </div>
            </div>
            {passWordChange && <ChangePasswordPopup setOpenModal={setPassWordChange} userCookie={userCookie} SetLoadingVisibility={SetLoadingVisibility} handleNotifyAlert={handleNotifyAlert} />}
            {editBasicDetails && <UpdateUserProfile setOpenModal={setEditBasicDetails} props={myProfileData}  setUpdateSelf={setUpdateSelf} userCookie={userCookie} SetLoadingVisibility={SetLoadingVisibility} handleNotifyAlert={handleNotifyAlert} />}
        </div>
        
    );
};

export default MyProfile;
