import React from "react";
import "./OrdersListItem.scss";

const OrdersListItem = ({ item, index,setContentOption,setOrderNo}) => {
//   current_status: (2)[(1, "placed")];
//   order_no: 10;
//   total_price: 45;
//   total_quantity: 2;
//   total_v_weight: 38;
//   total_weight: 38;
// console.log(item.current_status)
  return (
    <div className="ordersListItem-container" >
      <div className="ordersListItem-container__Sr-No">{index + 1}</div>
      <div className="ordersListItem-container__order-no">{item.order_no}</div>
      <div className="ordersListItem-container__totalWeight">{item.total_weight}g</div>
      <div className="ordersListItem-container__total-quantity">
      {item.total_quantity} units
      </div>
      {/* <div className="ordersListItem-container__total-price">Rs {item.total_price}</div> */}
      {item.current_status.number === 9 ? <div className="ordersListItem-container__status" style={{color:"#F25858"}}>{item.current_status.name}</div>:
       <>{item.current_status.number < 8 ? <div className="ordersListItem-container__status" style={{color:"#F2D058"}}>{item.current_status.name}</div>:
        <div className="ordersListItem-container__status" style={{color:"#6CCC54"}}>{item.current_status.name}</div>}</>}
        <button className="ordersListItem-container__actions"  onClick={()=>{setOrderNo(item.order_id); setContentOption("SpecficOrder")}}>Track Order</button>
    </div>
  );
};

export default OrdersListItem;
