import React, { useContext, useEffect, useRef, useState } from 'react'
import { DrawerContext } from '../context/DrawerContext';
import { CustomerDetailContext } from '../context/CustomerDetailContext';
import { Button, DatePicker, Form, Radio, RadioGroup } from 'rsuite';
import "./QuickCheckoutCart.scss"
import { backEndIP } from '../components/configuration';
import axios from 'axios';


const CartRowItem = ({ item, i, bagList, setBagList, SetLoadingVisibility, userCookie, handleDeleteCartItem }) => {

  // console.log("ITEM", item);

  const InputQuantityRef = useRef();
  const [quantity, setQuantity] = useState(item.quantity);
  const [btnColor, setBtnColor] = useState(["#FD6125", "#FD6125"]);

  const deleteItem = async (idx) => {
    item.quantity = 1;
    SetLoadingVisibility(false)
  };

  const handleItemQuantityInput = (product) => {


    let value = InputQuantityRef.current.value;
    if (value === '' || value === '0') {
      value = 1
      InputQuantityRef.current.value = value
    }
    const newQuantity = parseInt(value);
    setQuantity(newQuantity);

    // if (newQuantity === 0) {
    //     let idx = -1;
    //     bagList.forEach((item, index) => {
    //         if (item._id === product._id) idx = index;
    //     });
    //     SetLoadingVisibility(true);
    //     deleteItem(idx);
    //     setQuantity(null);
    // } else if (newQuantity > 0) {
    SetLoadingVisibility(true);
    axios({
      url: `${backEndIP}/cart-add/add-item-cart`,
      method: 'POST',
      headers: {
        authorization: userCookie.token,
      },
      data: { id: item._id, quantity: newQuantity - item.quantity }, // adjust quantity
    })
      .then((resp) => {
        setBagList(resp.data.data.cart);
      })
      .catch((err) => {
        const errorMsg = err.response?.message || 'Some error occurred';
        window.alert(errorMsg);
      })
      .finally(() => {
        SetLoadingVisibility(false);
      });
    // }
  };

  const handleChangeQuantity = (product) => {
    SetLoadingVisibility(true);

    if (product.quantity === 1) {
      //console.log("MINUS")
      let idx = -1;
      bagList.forEach((item, index) => {
        if (item._id === product._id) idx = index;
      });
      deleteItem(idx);
    } else {
      //console.log("BYPASS")
      axios({
        url: `${backEndIP}/cart-add/add-item-cart`,
        method: 'POST',
        headers: {
          authorization: userCookie.token,
        },
        data: { id: product._id, quantity: -1 }, // reduce by one
      })
        .then((resp) => {
          setBagList(resp.data.data.cart);
        })
        .catch((err) => {
          const errorMsg = err.response?.message || 'Some error occurred';
          window.alert(errorMsg);
        })
        .finally(() => {
          SetLoadingVisibility(false);
        });
    }
  };

  const handleIncreaseItemQuantity = (product) => {
    SetLoadingVisibility(true);
    axios({
      url: `${backEndIP}/cart-add/add-item-cart`,
      method: 'POST',
      headers: {
        authorization: userCookie.token,
      },
      data: { id: product._id, quantity: 1 }, // increase by one
    })
      .then((resp) => {
        setBagList(resp.data.data.cart);
      })
      .catch((err) => {
        const errorMsg = err.response?.message || 'Some error occurred';
        window.alert(errorMsg);
      })
      .finally(() => {
        SetLoadingVisibility(false);
      });
  };

  useEffect(() => {
    if (InputQuantityRef.current) {
      InputQuantityRef.current.value = item.quantity;
    }
  }, [item.quantity]);


  return (
    <div
      key={`${item.name}-${i}`}
      className="quickCart-container__drawer-body__item-list__drawer-item"
    >
      <div className="quickCart-container__drawer-body__item-list__drawer-item__item-name">
        {item.name}
      </div>
      <div className="quickCart-container__drawer-body__item-list__drawer-item__item-quantity">
        {item.available_quantity < item.quantity &&
          <div className="quickCart-container__drawer-body__item-list__drawer-item__item-quantity__availbleQtyErr"
          >Available Qty={item.available_quantity}</div>}
        <div className="quickCart-container__drawer-body__item-list__drawer-item__item-quantity__quantity-controls">
          <button
            className="quickCart-container__drawer-body__item-list__drawer-item__item-quantity__quantity-controls__minus-plus"
            //onMouseOver={() => setBtnColor(["white", "#FD6125"])}
            //onMouseOut={() => setBtnColor(["#FD6125", "#FD6125"])}
            onClick={() => { handleChangeQuantity(item) }}
          >
            <svg
              width="60%"
              height="60%"
              viewBox="0 0 14 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 1L1 0.999999"
                stroke={btnColor[0]}
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
          {/* <input
                                        className="quickCart-container__drawer-body__item-list__drawer-item__item-quantity__quantity-controls__quantity-input"
                                        // type="number"
                                        ref={InputQuantityRef}
                                        value={item.quantity}
                                        onChange={(e) =>
                                            handleChangeQuantity(parseInt(e.target.value), item._id)
                                        }
                                    /> */}

          <input
            className="quickCart-container__drawer-body__item-list__drawer-item__item-quantity__quantity-controls__quantity-input"
            type="number"
            ref={InputQuantityRef}
            defaultValue={item.quantity}
            onChange={(e) => {
              // console.log(e.target.value);
              if (e.target.value !== "" && e.target.value !== "0") {
                const newVal = parseInt(e.target.value);
                setQuantity(newVal);
              }
              else {
                setQuantity(1);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleItemQuantityInput(item);
            }}
          />
          <button
            className="quickCart-container__drawer-body__item-list__drawer-item__item-quantity__quantity-controls__minus-plus"
            //onMouseOver={() => setBtnColor(["#FD6125", "white"])}
            //onMouseOut={() => setBtnColor(["#FD6125", "#FD6125"])}
            onClick={() => handleIncreaseItemQuantity(item)}
          >
            <svg
              width="60%"
              height="60%"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 1L7 13"
                stroke={btnColor[1]}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M13 7L1 7"
                stroke={btnColor[1]}
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        {/* {item.available_quantity < item.quantity &&
          <div className="quickCart-container__drawer-body__item-list__drawer-item__item-quantity__backorderQtyErr"
          >Back Order Qty={item.quantity - item.available_quantity}</div>} */}
      </div>
      <div className="quickCart-container__drawer-body__item-list__drawer-item__item-quantity__quantity-controls__item-delete">
        <Button
          onClick={() => handleDeleteCartItem(i)}
          className="quickCart-container__drawer-body__item-list__drawer-item__item-quantity__quantity-controls__item-delete"
        >
          <img src={`${backEndIP}/deleteDustbin.svg`} alt="Delete" />
        </Button>
      </div>
    </div>
  );
};

export default CartRowItem;
