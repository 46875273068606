import React, { useRef } from "react";
import "./ConfirmOrderDelivery.scss";
import dayjs from "dayjs";
import axios from "axios";
import { backEndIP } from "../configuration";

function ConfirmOrderDelivery({ setOpenModal, orderMarkCnfrmDlvry, SetLoadingVisibility, userCookie, setOrderDelvryConfReq,
  handleNotifyAlert }) {
  const modalRef = useRef();

  const closeModal = e => {
    if (modalRef.current === e.target) {
      setOpenModal(false);
    }
  };

  const handelConfirmDelivery = ()=>{
    // setOpenModal(false);
    SetLoadingVisibility(true);
    setOrderDelvryConfReq([])
    let pendingOrder = []
    axios({
      url: backEndIP + `/order/confirm-order-delivery/${orderMarkCnfrmDlvry._id}`,
      method: "PUT",
      headers: {
        authorization: userCookie.token,
      },
      // data: { id: product._id , quantity:1 }
    }).then((resp)=>{
      console.log(resp)
      SetLoadingVisibility(false);
      handleNotifyAlert("notify", `${resp.data.message}`, "success", () => {} )
      setOrderDelvryConfReq(resp.data.data)
    }).catch((err)=>{
      SetLoadingVisibility(false);
      console.log("Err", err)
      if (err.response.data.message) {
        // window.alert(err.response.data.message);
        handleNotifyAlert("notify", err.response.data.message, "failed", () => { })
      }
      else {
        handleNotifyAlert("notify", "some error occurered", "failed", () => { });
      }
    })
    // console.log(pendingOrder)
    // if(pendingOrder.length){
    //   setOrderDelvryConfReq(pendingOrder)
    // }
  }

  // console.log("orderMarkCnfrmDlvry", orderMarkCnfrmDlvry)
  return (
    <div className="ConfirmOrderDelivery-modalBackground">
      <div className="ConfirmOrderDelivery-modalContainer" >
        <div className="ConfirmOrderDelivery-modalContainer__title">Confirm Order Delivery For Order No: {orderMarkCnfrmDlvry.order_no}</div>
        <div className="ConfirmOrderDelivery-modalContainer__dataContainer">
          <div className="ConfirmOrderDelivery-modalContainer__dataContainer__header">
            <div className="ConfirmOrderDelivery-modalContainer__dataContainer__header__title" style={{ color: "var(--primaryTextColor)" }}>Status : <span style={{ color: "#3FDA76", marginLeft: "calc(2px * var(--heightmultiplier)" }}>{orderMarkCnfrmDlvry.current_status.name}</span></div>
            <div className="ConfirmOrderDelivery-modalContainer__dataContainer__header__title">{dayjs(orderMarkCnfrmDlvry.delivered).format("MMM D, YYYY h:mm A").toString()}</div>
          </div>
          <div className="ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer">
            <div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__TotalItem'>
              Total Items : {orderMarkCnfrmDlvry.cart.length}
            </div>
            <div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__sub-Container'>
              <div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__sub-Container__title' >
                <div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__sub-Container__title__Sr-No'>S.No</div>
                <div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__sub-Container__title__name'>Name</div>
                <div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__sub-Container__title__weight'>Weight</div>
                <div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__sub-Container__title__Volumetricweight'>V - Weight</div>
                <div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__sub-Container__title__quantity'>Qty</div>
                <div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__sub-Container__title__totalWeight'>Total Weight</div>
              </div>
              <div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__sub-Container__dataContainer'>
                {orderMarkCnfrmDlvry.cart.map((item, i) => {

                  return (<div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__sub-Container__listDown' key={i}>
                    <div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__sub-Container__listDown__Sr-No'>{i + 1}</div>
                    <div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__sub-Container__listDown__name'>{item.name}</div>
                    <div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__sub-Container__listDown__weight'>{item.weight}</div>
                    <div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__sub-Container__listDown__Volumetricweight'>{item.v_weight}</div>
                    <div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__sub-Container__listDown__quantity'>{item.quantity}</div>
                    <div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__sub-Container__listDown__totalWeight'>{item.quantity * item.weight}</div>
                  </div>)
                })}
              </div>
              <div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__sub-Container__totalWeight'>
                <div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__sub-Container__totalWeight__title'>Cart Weight :</div>
                <div className='ConfirmOrderDelivery-modalContainer__dataContainer__detailsContainer__sub-Container__totalWeight__value'>{orderMarkCnfrmDlvry.total_weight}g</div>
                {/* <div className='ListDownItems-container__quantity'>Total Price</div> */}
                {/* <div className='ListDownItems-container__total-price'>{calculateTotalPrice()}Rs</div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="ConfirmOrderDelivery-modalContainer__confirmDelivery">
            <div className="ConfirmOrderDelivery-modalContainer__confirmDelivery__note"><span style={{color: "red"}}>*</span>To confirm your order delivery click below</div>
            <button className="ConfirmOrderDelivery-modalContainer__confirmDelivery__confirmBtn" onClick={handelConfirmDelivery}>Confirm</button>
          </div>
        {/* <div className="custom-alert-box-name">Alert</div> */}
        {/* <div className="ConfirmOrderDelivery-modalContainer__box-msg">{messageToDisplay.message}</div> */}
      </div>
    </div>
  );
}

export default ConfirmOrderDelivery;
