import React, { useEffect, useRef, useState } from "react";

import "./ListDownItem.scss";
import axios from "axios";
import { backEndIP } from "../configuration";

const ListDownItem = ({ item, index, deleteItem, SetLoadingVisibility, bagList, setBagList,  userCookie }) => {
  // console.log("updateQuantity=", updateQuantity)
  const [btnColor, setBtnColor] = useState(["#FD6125", "#FD6125"]);
  const InputQuantityRef = useRef();
  const [totalQuantity, setTotalQuantity] = useState(
    item.quantity * item.weight
  );
  const [totalPrice, setTotalPrice] = useState(item.quantity * item.price);
  const [quantity, setQuantity] = useState(item.quantity);
  useEffect(() => {
    if (InputQuantityRef.current) {
      InputQuantityRef.current.value = item.quantity;
    }
  }, [item.quantity]);

  useEffect(() => {
    InputQuantityRef.current.value = item.quantity;
    setTotalQuantity(item.quantity * item.weight);
    setTotalPrice(item.quantity * item.price);
  }, [item.quantity]);

  // const handleChangeQuantity = (quantity) => {
  //   if (quantity < 1 || isNaN(quantity)) {
  //     updateQuantity(index, 1);
  //   } else {
  //     updateQuantity(index, quantity);
  //   }
  //   setTotalQuantity(item.quantity * item.weight);
  //   setTotalPrice(item.quantity * item.price);
  // };
  const handleChangeQuantity = (product) => {
    SetLoadingVisibility(true);

    if (product.quantity === 1) {
      //console.log("MINUS")
      let idx = -1;
      bagList.forEach((item, index) => {
        if (item._id === product._id) idx = index;
      });
      deleteItem(idx);
    } else {
      //console.log("BYPASS")
      axios({
        url: `${backEndIP}/cart-add/add-item-cart`,
        method: 'POST',
        headers: {
          authorization: userCookie.token,
        },
        data: { id: product._id, quantity: -1 }, // reduce by one
      })
        .then((resp) => {
          console.log("resp", resp)
          setBagList(resp.data.data.cart);
        })
        .catch((err) => {
          console.log(err)
          const errorMsg = err.response?.message || 'Some error occurred';
          window.alert(errorMsg);
        })
        .finally(() => {
          SetLoadingVisibility(false);
        });
    }
  };

  const handleItemQuantityInput = async(product) => {


    let value = InputQuantityRef.current.value;
    if (value === '' || value === '0') {
      value = 1
      InputQuantityRef.current.value = value
    }
    const newQuantity = parseInt(value);
    setQuantity(newQuantity);

    // if (newQuantity === 0) {
    //     let idx = -1;
    //     bagList.forEach((item, index) => {
    //         if (item._id === product._id) idx = index;
    //     });
    //     SetLoadingVisibility(true);
    //     deleteItem(idx);
    //     setQuantity(null);
    // } else if (newQuantity > 0) {
    SetLoadingVisibility(true);
    await  axios({
      url: `${backEndIP}/cart-add/add-item-cart`,
      method: 'POST',
      headers: {
        authorization: userCookie.token,
      },
      data: { id: item._id, quantity: newQuantity - item.quantity }, // adjust quantity
    })
      .then((resp) => {
        console.log("resp", resp)
        setBagList(resp.data.data.cart);
      })
      .catch((err) => {
        console.log(err)
        const errorMsg = err.response?.message || 'Some error occurred';
        window.alert(errorMsg);
      })
      .finally(() => {
        SetLoadingVisibility(false);
      });
    // }
  };

  const handleIncreaseItemQuantity = (product) => {
    SetLoadingVisibility(true);
    axios({
      url: `${backEndIP}/cart-add/add-item-cart`,
      method: 'POST',
      headers: {
        authorization: userCookie.token,
      },
      data: { id: product._id, quantity: 1 }, // increase by one
    })
      .then((resp) => {
        console.log("resp", resp)
        setBagList(resp.data.data.cart);
      })
      .catch((err) => {
        console.log(err)
        const errorMsg = err.response?.message || 'Some error occurred';
        window.alert(errorMsg);
      })
      .finally(() => {
        SetLoadingVisibility(false);
      });
  };



  return (
    <div className="ListDownItem-container">
      <div className="ListDownItem-container__Sr-No">{index + 1}</div>
      <div className="ListDownItem-container__name">{item.name}</div>
      <div className="ListDownItem-container__unit-price">
        {/* Rs {item.price} */}
      </div>
      <div className="ListDownItem-container__weight">{item.weight}g</div>
      <div className="ListDownItem-container__Volumetricweight">
        {item.weight}g
      </div>
      <div className="ListDownItem-container__quantity">
        {item.available_quantity < item.quantity &&
          <div className="ListDownItem-container__quantity__availbleQtyErr"
          >Available Qty={item.available_quantity}</div>}
        <div className="ListDownItem-container__quantity__center">
          <button
            className="ListDownItem-container__quantity__center__minus-plus"
            onMouseOver={() => {
              setBtnColor(["white", "#FD6125"]);
            }}
            onMouseOut={() => {
              setBtnColor(["#FD6125", "#FD6125"]);
            }}
            onClick={() => { handleChangeQuantity(item) }}
          >
            <svg
              width="60%"
              height="10%"
              viewBox="0 0 14 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 1L1 0.999999"
                stroke={btnColor[0]}
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
          {/* {item.quantity} */}
          <input
            className="ListDownItem-container__quantity__center__input"
            type="number"
            ref={InputQuantityRef}
            defaultValue={item.quantity}
            onChange={(e) => {
              // console.log(e.target.value);
              if (e.target.value !== "" && e.target.value !== "0") {
                const newVal = parseInt(e.target.value);
                setQuantity(newVal);
              }
              else {
                setQuantity(1);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleItemQuantityInput(item);
            }}
          ></input>
          <button
            className="ListDownItem-container__quantity__center__minus-plus"
            onMouseOver={() => {
              setBtnColor(["#FD6125", "white"]);
            }}
            onMouseOut={() => {
              setBtnColor(["#FD6125", "#FD6125"]);
            }}
            onClick={() => handleIncreaseItemQuantity(item)}
          >
            <svg
              width="70%"
              height="70%"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 1L7 13"
                stroke={btnColor[1]}
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M13 7L1 7"
                stroke={btnColor[1]}
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
        {item.available_quantity < item.quantity &&
          <div className="ListDownItem-container__quantity__backorderQtyErr"
          >Back Order Qty={item.quantity-item.available_quantity}</div>}
      </div>
      <div className="ListDownItem-container__totalWeight">
        {totalQuantity}g
      </div>
      <div className="ListDownItem-container__total-price">
        {/* Rs {totalPrice} */}
        <img src={`${backEndIP}/deleteDustbin.svg`} alt="delete"
        className="ListDownItem-container__delete-img"
        onClick={()=>{deleteItem(index)}}></img>
        </div>
    </div>
  );
};

export default ListDownItem;
