import React, { useEffect } from "react";
import { useState } from "react";
import "./OrderDetailsTab.scss"
import dayjs from "dayjs";
import axios from "axios";
import { backEndIP } from "../configuration";
import CancellationOrderTemplateContent from "../CancellationOrderTemplateContent/CancellationOrderTemplateContent";
import CustomPopUpTemplete from "../CustomPopUpTemplete/CustomPopUpTemplete";


const OrderDetailsTab = ({
    props,
    setShowPopup,
    SetLoadingVisibility,
    userCookie,
    handleNotifyAlert,
    setUpdateSelf,
    setListItems,
    cancelOrderConfirm,
    setCancelOrderConfirm,
    orderListItems,
    orderListItemCopy,
    setOrderListItems,
    setOrderListItemCopy,
    updateOrderDetailsData,
    index, locations }) => {
    const [statusNumber, setStatusNumber] = useState(0)
    const [cancellationPopup, setCancellationPopup] = useState(false)
    const [userData, setUserData] = useState({})
    const currentOrderNo = props.order_no;

    useEffect(() => {
        if (!props.customerDetails) {
            SetLoadingVisibility(true)

            axios({
                url: backEndIP + `/order/getRespectedUser/${props.useremail}`,
                method: "GET",
                headers: {
                    authorization: userCookie.token,
                },
            }).then((Respdata) => {
                SetLoadingVisibility(false)
                console.log(props)
                console.log(Respdata)
                console.log(Respdata.data.data);
                setUserData(Respdata.data.data)
                //setUpdateSelf(prev => !prev)
            }).catch((err) => {
                //console.log(err);
                SetLoadingVisibility(false);
                handleNotifyAlert(
                    "notify",
                    `${err.response.data.message}`,
                    "failed",
                    () => { }
                );
            })
        }
    }, []);
    //setCurrentItem(props)
    // const locations = {
    //     "63932c7ab1cab628335ae969": "Jengjal",
    //     "63930f82865ec3abed90bc1b": "Mendipathar PHC",
    //     "639310eb865ec3abed90bc1f": "Pedaldoba PHC",
    //     "639311e2865ec3abed90bc23": "Nongalbibra PHC",
    //     "6393126c865ec3abed90bc27": "Shallang PHC",
    //     "639312ff865ec3abed90bc2b": "Samanda PHC",
    //     "63bd5b3ccafee0c35772f8f1": "Office",
    // }
    const orderStatus = {
        1: {
            key: "placed",
            name: "Placed"
        },
        2: {
            key: "approved",
            name: "Approved"
        },
        3: {
            key: "packed",
            name: "Packed"
        },
        4: {
            key: "sent_from_hub",
            name: "Sent From Hub"
        },
        5: {
            key: "intransit",
            name: "In Transit"
        },
        6: {
            key: "recieved_at_local_station",
            name: "Recieved At Local Station"
        },
        7: {
            key: "sent_out_delivery",
            name: "Sent Out Delivery"
        },
        8: {
            key: "delivered",
            name: "Delivered"
        },
        9: {
            key: "canceled",
            name: "Canceled"
        }
    }
    // console.log(props)

    const handleCancel = () => {
        setShowPopup(false);
    }
    const handleOrderCancel = () => {
        //setShowPopup(false);
        //SetLoadingVisibility(false)
        setCancellationPopup(true)
    }
    return (
        <div className="OrderDetailContainer">
            {/* <div onClick={handleCancel} className="cancelBtn">X</div> */}
            <div className="OrderDetailContainer__heading">
                <div className="OrderDetailContainer__heading__h1">View Order Details</div> <div onClick={handleCancel} className="OrderDetailContainer__heading__cancel">X</div></div>
            <div className="OrderDetailContainer__Container">
                <div className="OrderDetailContainer__detailsContainer">
                    <div className='OrderDetailContainer__detailsContainer__titleContainer'>
                        <div className='OrderDetailContainer__detailsContainer__titleContainer__left'>
                            <div className='OrderDetailContainer__detailsContainer__titleContainer__left__Title'>
                                Order No: {currentOrderNo}
                            </div>
                            <div className='OrderDetailContainer__detailsContainer__titleContainer__left__TotalItem'>
                                Total Items : {props.cart.length}
                            </div>
                        </div>
                        <div className='OrderDetailContainer__detailsContainer__titleContainer__right'>
                            <div className='OrderDetailContainer__detailsContainer__titleContainer__right__receivedOn'>{`Recieved On: ${props.recieved_on ? props.recieved_on : "System (OMS)"}`}</div>
                        </div>
                    </div>
                    <div className='OrderDetailContainer__detailsContainer__sub-Container'>
                        <div className='OrderDetailContainer__detailsContainer__sub-Container__title' >
                            <div className='OrderDetailContainer__detailsContainer__sub-Container__title__Sr-No'>S.No</div>
                            <div className='OrderDetailContainer__detailsContainer__sub-Container__title__name'>Name</div>
                            <div className='OrderDetailContainer__detailsContainer__sub-Container__title__weight'>Weight</div>
                            <div className='OrderDetailContainer__detailsContainer__sub-Container__title__Volumetricweight'>V - Weight</div>
                            <div className='OrderDetailContainer__detailsContainer__sub-Container__title__quantity'>Qty</div>
                            <div className='OrderDetailContainer__detailsContainer__sub-Container__title__totalWeight'>Total Weight</div>
                        </div>
                        {props.cart.length ? <div className='OrderDetailContainer__detailsContainer__sub-Container__dataContainer'>
                            {props.cart.map((item, i) => {

                                return (<div className='OrderDetailContainer__detailsContainer__sub-Container__listDown' key={i}>
                                    <div className='OrderDetailContainer__detailsContainer__sub-Container__listDown__Sr-No'>{i + 1}</div>
                                    <div className='OrderDetailContainer__detailsContainer__sub-Container__listDown__name'>{item.name}</div>
                                    <div className='OrderDetailContainer__detailsContainer__sub-Container__listDown__weight'>{item.weight}</div>
                                    <div className='OrderDetailContainer__detailsContainer__sub-Container__listDown__Volumetricweight'>{item.v_weight}</div>
                                    <div className='OrderDetailContainer__detailsContainer__sub-Container__listDown__quantity'>{item.quantity}</div>
                                    <div className='OrderDetailContainer__detailsContainer__sub-Container__listDown__totalWeight'>{item.quantity * item.weight}</div>
                                </div>)
                            })}
                        </div> :
                            <div className='OrderDetailContainer__detailsContainer__sub-Container__dataContainer'>
                                This cart is empty
                            </div>}
                        <div className='OrderDetailContainer__detailsContainer__sub-Container__totalWeight'>
                            <div className='OrderDetailContainer__detailsContainer__sub-Container__totalWeight__title'>{props.cart.length ? "Cart Weight": "Payload Weight"}</div>
                            <div className='OrderDetailContainer__detailsContainer__sub-Container__totalWeight__value'>{props.total_weight}g</div>
                            {/* <div className='ListDownItems-container__quantity'>Total Price</div> */}
                            {/* <div className='ListDownItems-container__total-price'>{calculateTotalPrice()}Rs</div> */}
                        </div>
                    </div>
                </div>
                <div className="OrderDetailContainer__Right">
                    <div className="OrderDetailContainer__dataContainer">
                        Current Status : {props.current_status.name}
                    </div>
                    <div className="OrderDetailContainer__content">
                        <div className='OrderDetailContainer__content__customerDataContainer'>
                            <div className='OrderDetailContainer__content__customerDataContainer__label'>Customer Details:</div>
                            {props.customerDetails ? <div className='OrderDetailContainer__content__customerDataContainer__value'>
                                <div className='OrderDetailContainer__content__customerDataContainer__value__UserDataContainer'>
                                    <div className='OrderDetailContainer__content__customerDataContainer__value__UserDataContainer__label'>
                                        Name : </div>{props.customerDetails.name}</div>
                                <div className='OrderDetailContainer__content__customerDataContainer__value__UserDataContainer'>
                                    <div className='OrderDetailContainer__content__customerDataContainer__value__UserDataContainer__label'>
                                        Email : </div>{props.customerDetails.email}</div>
                                <div className='OrderDetailContainer__content__customerDataContainer__value__UserDataContainer'>
                                    <div className='OrderDetailContainer__content__customerDataContainer__value__UserDataContainer__label'>
                                        Phone No : </div>{props.customerDetails.phone}</div>
                            </div> : <div className='OrderDetailContainer__content__customerDataContainer__value'>
                                <div className='OrderDetailContainer__content__customerDataContainer__value__UserDataContainer'>
                                    <div className='OrderDetailContainer__content__customerDataContainer__value__UserDataContainer__label'>
                                        Name : </div>{userData.userName}</div>
                                <div className='OrderDetailContainer__content__customerDataContainer__value__UserDataContainer'>
                                    <div className='OrderDetailContainer__content__customerDataContainer__value__UserDataContainer__label'>
                                        Email : </div>{userData.useremail}</div>
                                <div className='OrderDetailContainer__content__customerDataContainer__value__UserDataContainer'>
                                    <div className='OrderDetailContainer__content__customerDataContainer__value__UserDataContainer__label'>
                                        Phone No : </div>{userData.phone_no}</div>
                            </div>}
                        </div>
                        {/* {locations.map((startLoc) => {
                                if (startLoc._id === orderItem.destination_location) {
                                    return <div className="OrderTable__heading__rows__itemRow__item__endLoc">{startLoc.location_name}</div>
                                }
                            })} */}
                        <div className="OrderDetailContainer__content__type">
                            <div className="OrderDetailContainer__content__type__label">Delivery Type</div>
                            <div className="OrderDetailContainer__content__type__colon"> : </div>
                            <div className="OrderDetailContainer__content__type__value">{props.delivery_type}</div>
                        </div>
                        <div className="OrderDetailContainer__content__startLoc">
                            <div className="OrderDetailContainer__content__startLoc__label">Source Location</div>
                            <div className="OrderDetailContainer__content__startLoc__colon"> : </div>
                            {typeof props.source_location === "string" ? <> {locations.map((startLoc) => {
                                if (startLoc._id === props.source_location) {
                                    return <div className="OrderDetailContainer__content__startLoc__value">{startLoc.location_name}</div>
                                }
                            })}</> : <div className="OrderDetailContainer__content__startLoc__value">{props.source_location?.name}</div>}</div>
                        <div className="OrderDetailContainer__content__endLoc">
                            <div className="OrderDetailContainer__content__endLoc__label">Delivery Location</div>
                            <div className="OrderDetailContainer__content__endLoc__colon"> : </div>
                            {typeof props.destination_location === "string" ? <>{locations.map((startLoc) => {
                                if (startLoc._id === props.destination_location) {
                                    return <div className="OrderDetailContainer__content__startLoc__value">{startLoc.location_name}</div>
                                }
                            })} </> : <div className="OrderDetailContainer__content__startLoc__value">{props.destination_location?.name}</div>}
                        </div>
                        <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">Ordered Placed Date</div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(props.placed).format("MMM D, YYYY h:mm A").toString()}</div> </div>
                        {props.approved === null ? null : <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">Approved Date </div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(props.approved).format("MMM D, YYYY h:mm A").toString()}</div></div>}
                        {props.packed === null ? null : <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">Packed Date</div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(props.packed).format("MMM D, YYYY h:mm A").toString()}</div></div>}
                        {props.sent_from_hub === null ? null : <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">Sent From Hub On</div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(props.sent_from_hub).format("MMM D, YYYY h:mm A").toString()}</div></div>}
                        {props.intransit === null ? null : <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">In Transit</div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(props.intransit).format("MMM D, YYYY h:mm A").toString()}</div></div>}
                        {props.recieved_at_local_station === null ? null : <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">Received At Local Station</div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(props.recieved_at_local_station).format("MMM D, YYYY h:mm A").toString()}</div></div>}
                        {props.sent_out_delivery === null ? null : <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">Out For Delivery</div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(props.sent_out_delivery).format("MMM D, YYYY h:mm A").toString()}</div></div>}
                        {props.delivered === null ? <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">Expected Delivery Date</div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(props.delivery_date).format("MMM D, YYYY h:mm A").toString()}</div></div> : <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">Delivered On</div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(props.delivered).format("MMM D, YYYY h:mm A").toString()}</div></div>}
                        {props.cancelled === null ? null : <div className="OrderDetailContainer__content__date">
                            <div className="OrderDetailContainer__content__date__label">Cancelled Date</div>
                            <div className="OrderDetailContainer__content__date__colon"> : </div>
                            <div className="OrderDetailContainer__content__date__value">{dayjs(props.cancelled).format("MMM D, YYYY h:mm A").toString()}</div></div>}
                        {props.cancellationReason &&
                            <div className="OrderDetailContainer__content__date">
                                <div className="OrderDetailContainer__content__date__label">Cancellation Reason</div>
                                <div className="OrderDetailContainer__content__date__colon"> : </div>
                                <div className="OrderDetailContainer__content__date__value">{props.cancellationReason}</div></div>}
                    </div>
                    {props.current_status.number < 3 && <div className="OrderDetailContainer__button">

                        <button className="OrderDetailContainer__button__orderCancel" onClick={handleOrderCancel}>Cancel order</button></div>}
                </div>
            </div>
            <div className="OrderDetailContainer__Options">
                {/* <div onClick={handleCancel} id="cancelBtn">Cancel</div> */}
                <div onClick={handleCancel} id="OkayBtn">OK</div>
            </div>
            {cancellationPopup &&
                <CustomPopUpTemplete setOpenModal={setCancellationPopup} pageTitle={"Order Cancel Form"} content={
                    <CancellationOrderTemplateContent setCancellationPopup={setCancellationPopup} setShowPopup={setShowPopup}
                        orderData={props}
                        userCookie={userCookie}
                        SetLoadingVisibility={SetLoadingVisibility}
                        setUpdateSelf={setUpdateSelf}
                        handleNotifyAlert={handleNotifyAlert}
                        orderListItemCopy={orderListItemCopy}
                        orderListItems={orderListItems}
                        updateOrderDetailsData={updateOrderDetailsData}
                        index={index} />}
                />}
        </div>
    )
}
export default OrderDetailsTab;