import axios from "axios";
import { React, useEffect, useRef, useState } from "react";
import "./OrderCreateSelect.scss"
import DashboardCard from "../DashboardCard/DashboardCard";
import OrderModel from "../OrderModel/OrderModel";
import SelfOrderModel from "../SelfOrderModel/SelfOrderModel";
import { flushSync } from "react-dom";
import { useLocation } from "react-router-dom";
import { backEndIP } from "../configuration";

const OrderCreateSelect = ({ userCookie, setSelectedSubContent, selectedSubContent, setSelectedTab, setSelectedSub, userHubLocation, myLocation, allLocations, handleNotifyAlert, SetLoadingVisibility }) => {

    const [customerDetailsPopup, setCustomerDetailsPopup] = useState(false);
    const [selfOrderDetailsPopup, setSelfOrderDetailsPopup] = useState(false);
    const [createOrderHome, setCreateOrderHome] = useState(true);
    const location = useLocation();
    const [myHubLocation, setMyHubLocation] = useState({});

    useEffect(() => {
        console.log('URL changed to:', location.pathname);
        if (location.pathname === "/create-order") {
            setCreateOrderHome(true);
            setSelfOrderDetailsPopup(false);
            setCustomerDetailsPopup(false);
        }

    }, [location]);

    // console.log("UserCookie", userCookie)
    useEffect(() => {
        async function getHubLocation() {
            try {
                const response = await axios({
                    url: backEndIP + "/hub/get_hubById/",
                    method: "GET",
                    headers: {
                        authorization: userCookie.token
                    },
                })
                console.log("response", response.data.data);
                setMyHubLocation(response.data.data);
            } catch (error) {
                console.log("error", error);
            }
        }
        getHubLocation()
    }, [])

    const availableModules = [
        { label: "Self order", path: "create-order/self-order", tabName: "Create Order", check: myHubLocation?.flags?.selfOrder },
        { label: "Order for customer", path: "create-order/customer-order", tabName: "Create Order", check: myHubLocation?.flags?.customerOrder },
    ]
    useEffect(() => {
        setCreateOrderHome(true);
        setSelfOrderDetailsPopup(false);
        setCustomerDetailsPopup(false);
    }, [selectedSubContent])
    useEffect(() => {
        console.log("customerDetailsPopup", customerDetailsPopup)
    }, [])
    console.log("myHubLocation", myHubLocation, myHubLocation?.flags?.selfOrder, myHubLocation?.flags?.customerOrder)
    return (
        <div className="createOrderSelect-container">
            {createOrderHome && <>
                {/* <div className="createOrderSelect-container__Header">
                <div className="createOrderSelect-container__Header__title">Create Order</div>
            </div> */}
                <div className="createOrderSelect-container__Header2">
                    <div className="createOrderSelect-container__Header2__title">For whom you are ordering ?</div>
                </div>
                <div className="createOrderSelect-container__cardComponent">

                    {availableModules.map((modules) => {
                        console.log("modules", modules);
                        if (modules.check) {
                            // console.log("check", modules.check);
                            return <DashboardCard
                                modulelabel={modules.label}
                                navigateTo={modules.path}
                                setSelected={setSelectedTab}
                                subContent={setSelectedSub}
                                tabName={modules.tabName}

                                setCustomerDetailsPopup={setCustomerDetailsPopup}
                                setSelfOrderDetailsPopup={setSelfOrderDetailsPopup}
                                setCreateOrderHome={setCreateOrderHome} />
                        }
                        return null
                    })}
                </div></>}
            { customerDetailsPopup && myHubLocation?.flags?.customerOrder && //<div className="createOrderSelect-container__orderModel_container">
                <OrderModel
                    myHubLocation={myHubLocation}
                    userHubLocation={userHubLocation}
                    myLocation={myLocation}
                    allLocations={allLocations}
                    setCustomerDetailsPopup={setCustomerDetailsPopup}
                    deliveryType
                    handleNotifyAlert={handleNotifyAlert}
                    SetLoadingVisibility={SetLoadingVisibility}
                    userCookie={userCookie}
                    setCreateOrderHome={setCreateOrderHome}
                />
                //</div>
            }
            { selfOrderDetailsPopup && myHubLocation?.flags?.selfOrder &&
                // <div className="createOrderSelect-container__orderModel_container">
                <SelfOrderModel
                    myHubLocation={myHubLocation}
                    userHubLocation={userHubLocation}
                    myLocation={myLocation}
                    allLocations={allLocations}
                    setSelfOrderDetailsPopup={setSelfOrderDetailsPopup}
                    deliveryType
                    handleNotifyAlert={handleNotifyAlert}
                    SetLoadingVisibility={SetLoadingVisibility}
                    userCookie={userCookie}
                    setCreateOrderHome={setCreateOrderHome}
                    selectedSubContent={selectedSubContent}
                    setSelectedSubContent={setSelectedSubContent}
                    setSelectedTab={setSelectedTab}
                    setSubContent={setSelectedSubContent}
                />
                // </div>
            }

        </div>
    )
}


export default OrderCreateSelect;