import React, { useState, useEffect } from "react";
import axios from "axios";
import InventoryShowSubContent from "../InventoryShowSubContent/InventoryShowSubContent";
import { backEndIP } from "../configuration";
import "./HomeContent.scss";
import CustomDropDown from "../CustomDropDown/CustomDropDown";
import QuickCheckoutCart from "../../QuickCart/QuickCheckoutCart";

const HomeContent = ({
  SetLoadingVisibility,
  selectedCategory,
  setSelectedCategory,
  userCookie,
  handleNotifyAlert,
  BagList,
  setBagList,
  userHubLocation,
  myLocation,
  allLocations,
  setSelectedSubContent,
  handleDeleteCartItem,
  setContentOption,
  setOrderNo,
  setSelectedTab,
  totalWeight,
  setTotalPrice,
  setTotalWeight,
  calculateTotalPrice,
  calculateTotalWeight
}) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch category list from backend
    const fetchCategories = async () => {
      try {
        SetLoadingVisibility(true);
        const response = await axios({
          url: backEndIP + "/category/get_category",
          method: "GET",
          headers: {
            authorization: userCookie.token,
          },
        });
        // console.log("Response", response.data.data)
        //const data = await response.json();
        setCategories(response.data.data); // Assuming backend sends { categories: [...] }
        SetLoadingVisibility(false);
      } catch (error) {
        // console.error("Error fetching categories:", error);
        handleNotifyAlert("Failed to fetch categories.");
        SetLoadingVisibility(false);
      }
    };

    fetchCategories();
  }, [SetLoadingVisibility, userCookie]);
  // console.log("selectedCategory", selectedCategory)

  return (
    <div className="Home-container">
      <div
        className="Home-container__inventory-container"
        style={{ width: BagList.length ? "65%" : "100%" }}
      >
        <div className="Home-container__inventory-container__title">
          <CustomDropDown
            label={"All Category"}
            selectedItem={selectedCategory}
            setSelectedItem={setSelectedCategory}
            categories={categories}
            BagList={BagList}
          />
        </div>
        {selectedCategory ? (
          <>
            {categories.map((category) => {
              if (category.name === selectedCategory) {
                if (category.noOfItemsPresent >= 1) {
                  return (
                    <div className="Home-container__inventory-container__product-container">
                      <InventoryShowSubContent
                        SetLoadingVisibility={SetLoadingVisibility}
                        selectedCategory={selectedCategory}
                        userCookie={userCookie}
                        handleNotifyAlert={handleNotifyAlert}
                        BagList={BagList}
                        setBagList={setBagList}
                        userHubLocation={userHubLocation}
                        setSelectedSubContent={setSelectedSubContent}
                        allLocations={allLocations}
                        myLocation={myLocation}
                        handleDeleteCartItem={handleDeleteCartItem}
                        setContentOption={setContentOption}
                        setOrderNo={setOrderNo}
                        setSelectedTab={setSelectedTab}
                        setSelectedCategory={setSelectedCategory}
                        categories={categories}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div className="Home-container__inventory-container__product-container">
                      <div className="Home-container__inventory-container__product-container__noProductFound-text">{`Opps! No product found in ${selectedCategory} category`}</div>
                    </div>
                  );
                }
              }
            })}
          </>
        ) : (
          <div className="Home-container__inventory-container__product-container">
            <InventoryShowSubContent
              SetLoadingVisibility={SetLoadingVisibility}
              selectedCategory={selectedCategory}
              userCookie={userCookie}
              handleNotifyAlert={handleNotifyAlert}
              BagList={BagList}
              setBagList={setBagList}
              userHubLocation={userHubLocation}
              setSelectedSubContent={setSelectedSubContent}
              allLocations={allLocations}
              myLocation={myLocation}
              handleDeleteCartItem={handleDeleteCartItem}
              setContentOption={setContentOption}
              setOrderNo={setOrderNo}
              setSelectedTab={setSelectedTab}
              setSelectedCategory={setSelectedCategory}
              categories={categories}
            />
          </div>
        )}
      </div>
      <div
        className="Home-container__QuickCheckoutContainer"
        style={{ width: BagList.length ? "34%" : "" }}
      >
        {BagList.length > 0 && <div className='Home-container__QuickCheckoutContainer__product-description'>
          <QuickCheckoutCart
            bagList={BagList}
            setBagList={setBagList}
            userHubLocation={userHubLocation}
            handleDeleteCartItem={handleDeleteCartItem}
            handleNotifyAlert={handleNotifyAlert}
            SetLoadingVisibility={SetLoadingVisibility}
            setSelectedSubContent={setSelectedSubContent}
            allLocations={allLocations}
            userCookie={userCookie}
            myLocation={myLocation}
            setContentOption={setContentOption}
            setOrderNo={setOrderNo}
            setSelectedTab={setSelectedTab}
            totalWeight={totalWeight}
            setTotalPrice={setTotalPrice}
            setTotalWeight={setTotalWeight}
            calculateTotalPrice={calculateTotalPrice}
            calculateTotalWeight={calculateTotalWeight}
          />
        </div>
        }
      </div>
    </div>
  );
};

export default HomeContent;

// {/* <div className="Home-container">
// <div className="Home-container__inventory-container"  style={{ width: BagList.length ? "65%" : "100%" }}>
// <div className='Home-container__title'>
//   {/* <div className='Home-container__title__text'>{selectedCategory !== "" ? ` List of Available Products in ${selectedCategory}` :
//     "List of Available Products"} </div> */}
//   <div className='Home-container__title__dropdown'>
//     <CustomDropDown label={"All Category"} selectedItem={selectedCategory} setSelectedItem={setSelectedCategory} categories={categories} BagList={BagList} />
//   </div>
// </div>
// {/* Existing InventoryShowSubContent Component */}
// <div className="Home-container__subContainer">
//   {selectedCategory ? <>
//     {categories.map((category) => {
//       if (category.name === selectedCategory) {
//         if (category.noOfItemsPresent >= 1) {
//           return <div className="Home-container__subContainer__product-container"><InventoryShowSubContent
//             SetLoadingVisibility={SetLoadingVisibility}
//             selectedCategory={selectedCategory}
//             userCookie={userCookie}
//             handleNotifyAlert={handleNotifyAlert}
//             BagList={BagList}
//             setBagList={setBagList}
//             userHubLocation={userHubLocation}
//             setSelectedSubContent={setSelectedSubContent}
//             allLocations={allLocations}
//             myLocation={myLocation}
//             handleDeleteCartItem={handleDeleteCartItem}
//             setContentOption={setContentOption}
//             setOrderNo={setOrderNo}
//             setSelectedTab={setSelectedTab}
//             setSelectedCategory={setSelectedCategory}
//             categories={categories}
//           />
//           </div>
//         } else {
//           return <div className="Home-container__subContainer__product-container">
//             <div className="Home-container__subContainer__product-container__noProductFound-text">{`Opps! No product found in ${selectedCategory} category`}</div>
//           </div>

//         }
//       }
//     })}
//   </> :
//     <div className="Home-container__subContainer__product-container"><InventoryShowSubContent
//       SetLoadingVisibility={SetLoadingVisibility}
//       selectedCategory={selectedCategory}
//       userCookie={userCookie}
//       handleNotifyAlert={handleNotifyAlert}
//       BagList={BagList}
//       setBagList={setBagList}
//       userHubLocation={userHubLocation}
//       setSelectedSubContent={setSelectedSubContent}
//       allLocations={allLocations}
//       myLocation={myLocation}
//       handleDeleteCartItem={handleDeleteCartItem}
//       setContentOption={setContentOption}
//       setOrderNo={setOrderNo}
//       setSelectedTab={setSelectedTab}
//       setSelectedCategory={setSelectedCategory}
//       categories={categories}
//     />
//     </div>
//   }
//   </div>
//   <div>
//   {BagList.length > 0 && <div className='InventoryShowSubContent-container__subContainer__product-description'>
//     <QuickCheckoutCart
//       bagList={BagList}
//       setBagList={setBagList}
//       userHubLocation={userHubLocation}
//       handleDeleteCartItem={handleDeleteCartItem}
//       handleNotifyAlert={handleNotifyAlert}
//       SetLoadingVisibility={SetLoadingVisibility}
//       setSelectedSubContent={setSelectedSubContent}
//       allLocations={allLocations}
//       userCookie={userCookie}
//       myLocation={myLocation}
//       setContentOption={setContentOption}
//       setOrderNo={setOrderNo}
//       setSelectedTab={setSelectedTab}
//     />
//   </div>
//   }
//   </div>

// </div>
// </div> */}
