import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react'
import "./OrderModel.scss"
import { CustomerDetailContext } from '../../context/CustomerDetailContext';
import { useNavigate } from 'react-router-dom';
import { DatePicker, Form, Radio, RadioGroup, Stack } from 'rsuite';
import axios from 'axios';
import { backEndIP } from '../configuration';
import { checkWeightLimitFunction } from '../../Utility/checkWeightLimitFunction';
import { fetchOrderDeliverySlots } from '../../Utility/fetchOrderDeliverySlots';
const OrderModel = ({
    userHubLocation,
    myLocation,
    allLocations,
    setCustomerDetailsPopup,
    handleNotifyAlert,
    SetLoadingVisibility,
    userCookie,
    setCreateOrderHome,
    myHubLocation
}) => {
    const phoneNoElementRef = useRef()

    // recievedOn, setRecievedOn
    const [avaiblableDestLocations, setAvaiblableDestLocations] = useState({});
    const { payloadDetails, setPayloadDetails, deliveryDate, setDeliveryDate, deliveryType, setDeliveryType, orderType, setOrderType, customerData, setCustomerData, destLocation, setDestLocation, recievedOn, setRecievedOn, urgentReason, setUrgentReason, availableOrderSlots, setAvailableOrderSlots } = useContext(CustomerDetailContext)

    // const [emailError, setEmailError] = useState(false)
    // const [PhoneNoError, setPhoneNoError] = useState(false)
    const [hubLocation, setHubLocation] = useState({ location_id: userHubLocation, location_name: "" })
    // const [deliveryDate, setDeliveryDate] = useState(dayjs(new Date()).add(1, "day").hour(18).minute(0))
    const [orderNumber, setOrderNumber] = useState(0);
    const [orderPayload, setOrderPayload] = useState(0);
    // const [deliveryType, setDeliveryType] = useState("");
    // const [orderType, setOrderType] = useState("Customer")
    const [emailError, setEmailError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [nameError, setNameError] = useState("");
    const [payloadTypeError, setPayloadTypeError] = useState("");
    const [destinationError, setDestinationError] = useState("");
    const [recievedOnError, setRecievedOnError] = useState("");
    const [deliveryTypeError, setDeliveryTypeError] = useState("");
    const [orderNumberError, setOrderNumberError] = useState("");
    const [payloadWeightError, setPayloadWeightError] = useState("");
    const [locationBasedUsers, setLocationBasedUsers] = useState([]);
    const [locationBasedUser, setLocationBasedUser] = useState({});
    const [locationBasedUserError, setLocationBasedUserError] = useState("");
    const [urgentReasonError, setUrgentReasonError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [checkError, setCheckError] = useState("");
    // const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [selectDateVisibility, setSelectDateVisibility] = useState(false);
    const emailFormate = /^\w+([\.-_]?\w+)*@\w*(\.\w{2,3})+$/
    const [check, setCheck] = useState("")
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchOrderDeliverySlots(userCookie, setAvailableOrderSlots, handleNotifyAlert);
            } catch (error) {
                console.error("Error fetching delivery slots:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        // console.log(deliveryDate, destLocation)
        const tempMyLoc = {}
        allLocations.forEach((loc, i) => {
            if (loc._id === myLocation) {
                tempMyLoc[myLocation] = loc.location_name;
                tempMyLoc[loc.location_name] = loc._id;
            }
            if (allLocations[i]._id === userHubLocation) {
                setHubLocation({ location_id: userHubLocation, location_name: `${allLocations[i].location_name} (${allLocations[i].tag_name})` })
            }
        })
        setAvaiblableDestLocations(tempMyLoc)

    }, [allLocations])

    useEffect(() => {
        setOrderType("Customer")
    }, [recievedOn])

    useEffect(() => {
        if (destLocation !== "Select")
            getDestinationLocationUsers()
    }, [destLocation])

    const getDestinationLocationUsers = async () => {
        console.log(" destLocation.split(", ")[0]", destLocation.split(",")[0]);
        try {
            const response = await axios({
                url: backEndIP + "/user/get_location_users",
                method: "POST",
                headers: {
                    authorization: userCookie.token
                },
                data: {
                    location: destLocation.split(",")[0]
                }
            })
            console.log(response);
            if (response.data.message) {
                setLocationBasedUsers([]);
                setLocationBasedUserError("No User Found For this location")
            } else {
                if (response.data.data.length === 0) {
                    setLocationBasedUserError("No User Found For this location")
                } else {
                    setLocationBasedUserError("")
                }
                setLocationBasedUsers(response.data.data);

            }

        } catch (error) {
            console.log("error", error);
        }

    }
    useEffect(() => {
        if (!myHubLocation.flags.customerOrder) {
            setCustomerDetailsPopup(false);
            setCreateOrderHome(true);
        }
    }, [])
    const PlaceUserOrder = () => {
        console.log({
            orderType: orderType,
            cart: [],
            destination_location: destLocation.split(",")[0],
            source_location: userHubLocation,
            delivery_date: deliveryDate,
            delivery_type: deliveryType,
            payload: orderPayload,
            customerData: { ...customerData }
        })
        SetLoadingVisibility(true);

        axios({
            url: backEndIP + "/order/placed-manual-order",
            method: "POST",
            headers: {
                authorization: userCookie.token,
            },
            data: {
                // orderType: orderType,
                destination_location: destLocation.split(",")[0],
                source_location: userHubLocation,
                delivery_date: deliveryDate,
                delivery_type: deliveryType,
                orderNo: orderNumber,
                recievedOn: recievedOn,
                payload: orderPayload,
                hubLocation: userHubLocation,
                payloadDetails: payloadDetails,
                order_delivery_type: orderType,
                urgentReason: urgentReason !== "Select" ? urgentReason : null,
                customerData: { name: locationBasedUser.userName, email: locationBasedUser.userEmail, phoneNo: locationBasedUser.phoneNumber }
            }
            // data: { id: product._id , quantity:1 }
        })
            .then((resp) => {
                SetLoadingVisibility(false);
                console.log(resp.data);
                handleNotifyAlert("notify", "Order placed succesfully", "success", () => { })
                setDestLocation("Select")
                setDeliveryType("")
                setCustomerData({ name: "", email: "", phoneNo: "" })
                setOrderType("Self")
                setSelectDateVisibility(false)
                setOrderNumber(0);
                setRecievedOn("");
                setPayloadDetails({ type: "", description: "", weight: 0 })
                setCustomerDetailsPopup(false);
                setCreateOrderHome(true);
                setDeliveryDate("")
            })
            .catch((err) => {
                console.log(err)
                SetLoadingVisibility(false);
                if (err.response.data.message) {
                    // window.alert(err.response.data.message);
                    handleNotifyAlert("notify", err.response.data.message, "failed", () => { })
                }
                else handleNotifyAlert("notify", "some error occurered", "failed", () => { });
                //console.log(err);
                fetchOrderDeliverySlots(userCookie, setAvailableOrderSlots, handleNotifyAlert);
            });
    }
    const handlePlaceOrderClick = () => {
        //  console.log(phoneNoElementRef.current.value
        if (!handleAddCustomer())
            return;

        if (orderType === "Customer" && recievedOn === "Shopify") {
            // console.log(phoneNoElementRef.current.value)
            if (locationBasedUser.name === "" && locationBasedUser.email === "" && recievedOn === "") {
                handleNotifyAlert("notify", "Please fill all the customers details", "alert", () => { })
            }
            // else if (phoneNoElementRef.current.value.length !== 10) {
            //     setPhoneNoError(true)
            //     handleNotifyAlert("notify", "Enter Vaild Phone no", "alert", () => { })
            //     setCustomerDetailsPopup(true)
            // } else if (customerData.email.match(emailFormate) === null) {
            //     setEmailError(true)
            //     handleNotifyAlert("notify", "Enter User's Vaild Email", "alert", () => { })
            //     setCustomerDetailsPopup(true)
            // }
            else {
                // const tempCustomerData ={ ...customerData, phoneNo: `+91${phoneNoElementRef.current.value}` }
                // setCustomerData(tempCustomerData)
                if (destLocation !== "Select" && deliveryType !== "") {
                    // console.log("customerData", customerData)
                    handleNotifyAlert("alert", `Are you sure you want to place order for Destination location : ${destLocation.split(",")[1]}?`, "alert", PlaceUserOrder)
                    setCustomerDetailsPopup(true)
                }
                else if (destLocation === "Select") {
                    handleNotifyAlert("notify", "Please select destination Location", "alert", () => { })
                    setCustomerDetailsPopup(true)
                }
                else if (deliveryType === "") {
                    handleNotifyAlert("notify", `Please select "delivery type" for the order`, "alert", () => { })
                    setCustomerDetailsPopup(true)
                }
                // else {
                //     SetLoadingVisibility(false);
                //     handleNotifyAlert("notify", "Please update Cart first", "alert", () => { })
                // }
            }
        }
    }
    const allrecievedOn = [
        "Whatsapp", "Call", "Email", "Shopify"
    ]
    const urgentReasonOption = [
        { label: "Life risk" },
        { label: "Critical supplies" }
    ]
    function handleAddCustomer() {
        let hasError = false;
        console.log("recievedOn");
        const setError = (message, errorSetter) => {
            errorSetter(message);
            hasError = true;
        };

        // Validate customer data
        // if (!customerData.email) {
        //     setError("Email is required", setEmailError);
        // } else if (!customerData.email.match(emailFormate)) {
        //     setError("Enter a correct email format", setEmailError);
        // }

        // if (!customerData.phoneNo) {
        //     setError("Phone number is required", setPhoneError);
        // }

        // if (!customerData.name) {
        //     setError("Name is required", setNameError);
        // }

        if (destLocation === "Select") {
            setError("Please select a destination", setDestinationError);
        }

        if (recievedOn === "Select" || recievedOn === "") {
            console.log(recievedOn);
            setError("Please select received on", setRecievedOnError);
        }

        if (deliveryType === "") {
            setError("Delivery type is required", setDeliveryTypeError);
        }
        if (deliveryType === "Urgent") {
            if (urgentReason === "Select")
                setError("Please Select urgent reason", setUrgentReasonError);
        }

        // Validate specific conditions
        if (recievedOn === "Shopify" && (orderNumber === 0 || orderNumber === "")) {
            setError("Order number cannot be zero or empty", setOrderNumberError);
        }

        if (["Shopify"].includes(recievedOn)) {
            if (!payloadDetails.type)
                setError("please entre valid type", setPayloadTypeError);
            if (payloadDetails.type.length > 3)
                setError("Length must be > 3", setPayloadTypeError);

            if (payloadDetails.weight === 0)
                setError("please entre valid weight", setPayloadWeightError);

            if (!payloadDetails.description)
                setError("please entre valid description", setDescriptionError);
            if (payloadDetails.description.length >= 6)
                setError("Length must be >= 6", setDescriptionError);
        }

        // Final validation
        if (hasError) {
            setCheckError("All fields required");
            return false;
        }

        // Navigate based on receivedOn
        if (["Whatsapp", "Call", "Email"].includes(recievedOn)) {
            setCustomerDetailsPopup(false);
            console.log("njkdfajdjdfjdfjasdbjksdnjdfgnbajksdfjksdfgbjsdfbgnsdfgbn")
            navigate("/all-product");
            console.log("/all-product")
            return false;
        }

        // Default action
        setCustomerDetailsPopup(false);
        return true;
    }
    const handleDeliveryRadioChange = (value) => {
        setDeliveryType(value);
        if (value === "Urgent") {
            const currentDate = new Date(); // Get the current date and time
            const currentHour = currentDate.getHours();
            const currentMinutes = currentDate.getMinutes();

            let deliveryDate = new Date(currentDate); // Create a copy of the current date

            if (currentHour < 12 || (currentHour === 12 && currentMinutes === 0)) {
                // If time is before or equal to 12:00 noon
                deliveryDate.setHours(18, 0, 0, 0); // Set time to 6:00 PM
            } else {
                // If time is after 12:00 noon
                deliveryDate.setDate(deliveryDate.getDate() + 1); // Move to the next day
                deliveryDate.setHours(12, 0, 0, 0); // Set time to 12:00 noon
            }

            const formattedDate = dayjs(deliveryDate).format("MM/DD/YYYY hh:mm A"); // Format the date

            setSelectDateVisibility(false);
            setDeliveryDate(formattedDate);
        } else {
            setSelectDateVisibility(true);
            // setIsDatePickerOpen(true);
        }
        setDeliveryTypeError("")
        // console.log("deliverytype", deliveryType);
    };
    const handleOrderModelClose = () => {
        setCustomerDetailsPopup(false);
        setCreateOrderHome(true)
        // setDestLocation("Select")
        // setDeliveryType("")
        // setCustomerData({ name: "", email: "", phoneNo: "" })
        // setOrderType("Self")
        // setSelectDateVisibility(false)
        // setOrderNumber(0);
        // setRecievedOn("");
        // setPayloadDetails({ type: "", description: "", weight: 0 })
    }
    console.log("userHubLocation=", userHubLocation);

    return (
        <div className='OrderModel-container'>
            <h3 className='OrderModel-container__heading'>Add Order Details</h3>
            <div className="OrderModel-container__totalItems__locationCont">
                <div className="OrderModel-container__inputFiled">
                    <div className="OrderModel-container__inputFiled__label">Source Location<span style={{ color: "red" }}>*</span></div>
                    <div className="OrderModel-container__inputFiled__value">
                        <input
                            readOnly
                            value={hubLocation.location_name}
                            //placeholder="Enter Confirm Password"
                            className="OrderModel-container__inputFiled__value__input"

                        //onChange={(e) => { setUpdatePasswordData({ ...updatePasswordData, ["confirm_password"]: e.target.value }) }}
                        /></div>
                </div>

                <div
                    className="OrderModel-container__inputFiled"
                    style={{
                        border:
                            destinationError !== "" && destLocation === "Select"
                                ? "2px solid red"
                                : "2px solid var(--primaryTextColor)"
                    }}
                >
                    <div className="OrderModel-container__inputFiled__label">
                        Destination Location<span style={{ color: "red" }}>*</span>
                    </div>

                    <div className="OrderModel-container__inputFiled__value">
                        <select
                            value={destLocation}
                            onChange={(e) => {
                                const selectedValue = e.target.value;

                                // Handle validation
                                if (selectedValue === "Select") {
                                    setDestinationError("Please Select Destination Location");
                                    setDestLocation("Select");
                                } else {
                                    setDestinationError("");
                                    setDestLocation(selectedValue);

                                    // Call API after setting the location
                                    // await getDestinationLocationUsers();
                                }
                            }}
                            className="OrderModel-container__inputFiled__value__dropDown"
                        >
                            <option value="Select">Select</option>
                            {allLocations.map((option) => {
                                // Exclude "Office" and "Meghalaya" locations
                                if (option.tag_name !== "Office" && option.tag_name !== "Meghalaya") {
                                    return (
                                        <option key={option._id} value={[option._id, option.location_name]}>
                                            {option.location_name}
                                        </option>
                                    );
                                }
                                return null; // Avoid returning undefined
                            })}
                        </select>
                    </div>

                    <div className="OrderModel-container__inputFiled__error">
                        {destinationError}
                    </div>
                </div>


            </div>
            <div className='OrderModel-container__item1'>
                <div className="OrderModel-container__item1__inputFiled" style={{ border: (recievedOnError !== "") ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                    <div className="OrderModel-container__inputFiled__label" >Recieved On<span style={{ color: "red" }}>*</span></div>
                    <div className="OrderModel-container__inputFiled__value">
                        <select
                            value={recievedOn}
                            onChange={(e) => {
                                if (e.target.value === "Select") {
                                    setRecievedOnError("Please select secieved on");
                                } else {
                                    setRecievedOnError("");
                                }
                                setRecievedOn(e.target.value)

                                // setCheck("")
                            }}
                            required
                            className="OrderModel-container__inputFiled__value__dropDown"
                        >
                            <option value={"Select"}>
                                Select
                            </option>
                            {allrecievedOn.map((option) => <option key={option} value={option}> {option}</option>)}</select></div>
                    <div className='OrderModel-container__inputFiled__error'>{recievedOnError}</div>
                </div>
                <div
                    className="OrderModel-container__item1__inputFiled"
                    style={{ border: (locationBasedUserError !== "") ? "2px solid red" : "2px solid var(--primaryTextColor)" }}
                >
                    <div className="OrderModel-container__inputFiled__label">
                        Location User<span style={{ color: "red" }}>*</span>
                    </div>

                    <div className="OrderModel-container__inputFiled__value">
                        <select
                            value={locationBasedUser.userEmail || "Select"}
                            onChange={(e) => {
                                console.log("Selected User:", locationBasedUsers, e.target.value);
                                const selectedUser = locationBasedUsers?.find(
                                    (user) => user.userEmail === e.target.value

                                );
                                setLocationBasedUser(() => {

                                    setCustomerData({
                                        name: selectedUser.userName,
                                        email: selectedUser.userEmail,
                                        phoneNo: selectedUser.phoneNumber
                                    })

                                    return selectedUser || {}
                                });
                                console.log("Selected User:", selectedUser);
                            }}
                            required
                            className="OrderModel-container__inputFiled__value__dropDown"
                        >
                            <option value="Select">Select</option>
                            {locationBasedUsers?.map((option) => (
                                <option key={option.userEmail} value={option.userEmail}>
                                    {option.userName}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="OrderModel-container__inputFiled__error">
                        {locationBasedUserError}
                    </div>
                </div>
            </div>
            <div className="OrderModel-container__totalItems__locationCont1">
                <div className="OrderModel-container__totalItems__locationCont1__label"> Customer Details</div>
                <div className="OrderModel-container__customerInput">
                    <div className="OrderModel-container__customerInput__label">Name<span style={{ color: "red" }}>*</span></div>
                    <div className="OrderModel-container__customerInput__value" >
                        <input type="text"
                            style={{ border: (nameError !== "") ? "1px solid red" : "1px solid var(--primaryTextColor)" }}
                            value={locationBasedUser.userName}
                            placeholder="Enter name"
                            className="OrderModel-container__customerInput__value__input"
                            // onChange={(e) => {
                            //     if (e.target.value.length < 5) {
                            //         setNameError("name must be at least 5 characters");
                            //     } else {
                            //         setNameError("");
                            //     }
                            //     setCustomerData({ ...customerData, "name": e.target.value })
                            //     // setCheck("")
                            // }}
                            readOnly
                        />
                    </div>
                    <div className='OrderModel-container__customerInput__error'>{nameError}</div>

                </div>
                <div className="OrderModel-container__customerInput" >
                    <div className="OrderModel-container__customerInput__label"
                    >Email<span style={{ color: "red" }}>*</span></div>
                    <div className="OrderModel-container__customerInput__value" >
                        <input
                            // style={{ border: (nameError !== "") ? "1px solid red" : "1px solid var(--primaryTextColor)" }}
                            type="email"
                            style={{


                                border: ((customerData.email.match(emailFormate) === null && customerData.email) || emailError !== "")
                                    ? "1px solid red"
                                    : "1px solid var(--primaryTextColor)",
                            }}
                            value={locationBasedUser.userEmail}
                            // style={{ color: emailError === true ? "red" : "var(--primaryTextColor)" }}
                            placeholder="Enter Email"
                            className="OrderModel-container__customerInput__value__input"
                            // onChange={(e) => {
                            //     // if (e.target.value.length < 5) {
                            //     //     setNameError("name must be at least 5 characters");
                            //     // } else {
                            //     setEmailError("");
                            //     // }
                            //     setCustomerData({ ...customerData, "email": e.target.value })
                            //     // setEmailError(false);

                            // }}
                            readOnly
                        /></div>
                    <div className='OrderModel-container__customerInput__error'>{emailError}</div>
                </div>
                <div className="OrderModel-container__customerInput">
                    <div className="OrderModel-container__customerInput__label">Phone<span style={{ color: "red" }}>*</span></div>
                    <div className="OrderModel-container__customerInput__value">


                        <input
                            ref={phoneNoElementRef}
                            type="text"
                            value={locationBasedUser.phoneNumber?.slice(3)}
                            style={{
                                border: ((customerData.phoneNo.replace("+91", "").length < 10 && customerData.phoneNo.replace("+91", "").length > 0) || phoneError !== "")
                                    ? "1px solid red"
                                    : "1px solid var(--primaryTextColor)",

                            }}
                            placeholder="Enter Phone No"
                            className="OrderModel-container__customerInput__value__input2"
                            // onChange={(e) => {
                            //     const input = e.target.value;

                            //     const sanitizedInput = input.replace(/[^0-9]/g, "");

                            //     if (sanitizedInput.length <= 10) {
                            //         setCheck("");
                            //         setPhoneNoError(false);

                            //         const formattedPhone = `+91${sanitizedInput}`;
                            //         setCustomerData({ ...customerData, phoneNo: formattedPhone });
                            //     } else {
                            //         // setPhoneNoError(true); 
                            //         setPhoneError("number must be of length 10");
                            //     }
                            //     setPhoneError("")
                            // }}
                            readOnly
                        />

                    </div>
                    <div className='OrderModel-container__customerInput__error'>{phoneError}</div>
                </div>
                {/* <div className="OrderModel-container__customerInput" >
                    <div className="OrderModel-container__customerInput__label">Recieved On</div>
                    <div className="OrderModel-container__customerInput__value">
                        <select

                            value={recievedOn}
                            onChange={(e) => {
                                setRecievedOn(e.target.value)

                                setCheck("")
                            }}
                            required
                            placeholder="Select Manual Order Type"
                            className="OrderModel-container__customerInput__value__input2 OrderModel-container__customerInput__value__input1"
                        >
                            <option value={"Select"}>
                                Select
                            </option>
                            {allrecievedOn.map((option) => <option key={option} value={option}> {option}</option>)}
                        </select>
                    </div>
                </div> */}
            </div>

            {recievedOn === "Shopify" &&
                <div className='OrderModel-container__Item4'>
                    <div className="OrderModel-container__Item4__inputFiled" style={{ border: (payloadTypeError !== "" && payloadDetails.type.length < 3) ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                        <div className="OrderModel-container__inputFiled__label">Payload Type<span style={{ color: "red" }}>*</span></div>
                        <div className="OrderModel-container__inputFiled__value">
                            <input
                                value={payloadDetails.type}
                                onChange={(e) => {
                                    if (e.target.value.length < 3) {
                                        // setErrorCheck("All fields required")
                                        setPayloadTypeError("Length must be > 3")
                                    } else {
                                        // setErrorCheck("")
                                        setPayloadTypeError("")
                                    }
                                    setPayloadDetails({ ...payloadDetails, type: e.target.value })
                                }}
                                type='text'
                                placeholder="Enter payload type"
                                className="OrderModel-container__inputFiled__value__input"
                            /></div>
                        <div className='OrderModel-container__inputFiled__error'>{payloadTypeError}</div>
                    </div>
                    <div className="OrderModel-container__Item4__inputFiled" style={{ border: (descriptionError !== "" && payloadDetails.description.length < 6) ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                        <div className="OrderModel-container__inputFiled__label">Payload Description<span style={{ color: "red" }}>*</span></div>
                        <div className="OrderModel-container__inputFiled__value">
                            <input
                                value={payloadDetails.description}
                                onChange={(e) => {
                                    if (e.target.value.length < 6) {
                                        // setErrorCheck("All fields required")
                                        setDescriptionError("Length must be > 6")
                                    } else {
                                        // setErrorCheck("")
                                        setDescriptionError("")
                                    }
                                    setPayloadDetails({ ...payloadDetails, description: e.target.value })
                                }}
                                type='text'
                                placeholder="Enter payload description"
                                className="OrderModel-container__inputFiled__value__input"
                            /></div>
                        <div className='OrderModel-container__inputFiled__error'>{descriptionError}</div>
                    </div>
                    <div className="OrderModel-container__Item4__inputFiled" style={{ border: (checkWeightLimitFunction(payloadDetails.weight) || payloadWeightError !== "") ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                        <div className="OrderModel-container__inputFiled__label">Payload weight<span style={{ color: "red" }}>*</span></div>
                        <div className="OrderModel-container__inputFiled__value">
                            <input
                                type='number'
                                value={payloadDetails.weight}
                                onChange={(e) => {
                                    setPayloadDetails({ ...payloadDetails, weight: e.target.value });
                                    if (checkWeightLimitFunction(e.target.value)) {
                                        // setErrorCheck("weight limit 2500");
                                        setPayloadWeightError("weight limit 2500")
                                    } else {
                                        // setErrorCheck("");
                                        setPayloadWeightError("")
                                    }
                                }}
                                placeholder="Enter payload"
                                className="OrderModel-container__inputFiled__value__input"
                            />g</div>
                        <div className='OrderModel-container__inputFiled__error'>{payloadWeightError}</div>
                    </div>

                    <div className="OrderModel-container__Item4__inputFiled" style={{ border: (orderNumberError !== "") ? "2px solid red" : "2px solid var(--primaryTextColor)" }}>
                        <div className="OrderModel-container__inputFiled__label">Order Number<span style={{ color: "red" }}>*</span></div>
                        <div className="OrderModel-container__inputFiled__value">
                            <input
                                type='number'
                                value={orderNumber}
                                onChange={(e) => {
                                    setOrderNumber(e.target.value)
                                    setOrderNumberError("")
                                }}

                                placeholder="Enter Order Number"
                                className="OrderModel-container__inputFiled__value__input"
                            /></div>
                        <div className='OrderModel-container__inputFiled__error'>{orderNumberError}</div>
                    </div>
                </div>}
            <div className="OrderModel-container__totalItems__locationCont2">
                <Form.Group
                    controlId="delivery-type"
                    className="OrderModel-container__totalItems__locationCont2__delivery-type-group"
                >
                    <Form.ControlLabel className="OrderModel-container__totalItems__locationCont2__delivery-type-label">
                        Delivery Type<span style={{ color: "red" }}>*</span>
                    </Form.ControlLabel>
                    <RadioGroup
                        inline
                        name="controlled-radio-buttons-group"
                        value={deliveryType}
                        onChange={handleDeliveryRadioChange}
                        className="OrderModel-container__totalItems__locationCont2__delivery-type-radio-group"
                    >
                        <Radio value="Urgent" className="OrderModel-container__totalItems__locationCont2__delivery-type-radio">
                            Urgent
                        </Radio>
                        <Radio value="Scheduled" className="OrderModel-container__totalItems__locationCont2__delivery-type-radio">
                            Scheduled

                        </Radio>
                    </RadioGroup>
                    {deliveryType === "Scheduled" &&
                        <DatePicker
                            format="dd/MM/yyyy"
                            value={deliveryDate}
                            oneTap={true}
                            onChange={(date) => {
                                if (date) {
                                    const updatedDate = dayjs(date).hour(18).minute(0).second(0).millisecond(0).toDate();
                                    setDeliveryDate(updatedDate);
                                } else {
                                    setDeliveryDate(date)
                                }
                            }}
                            className='OrderModel-container__totalItems__locationCont2__delivery-type-radio__datePicker'
                            // open={selectDateVisibility}
                            placement="topStart"
                            // shouldDisableDate={(date) => {
                            //     const availableDates = availableOrderSlots.map(slot => dayjs(slot).startOf('day').toString());
                            //     const selectedDate = dayjs(date).startOf('day').toString();
                            //     return !availableDates.includes(selectedDate);
                            // }}
                            shouldDisableDate={(date) => {
                                const availableDates = availableOrderSlots.map(slot => dayjs(slot).startOf('day'));
                                const selectedDate = dayjs(date).startOf('day');
                                const today = dayjs().startOf('day');

                                return selectedDate.isSame(today) || !availableDates.some(availableDate => availableDate.isSame(selectedDate));
                            }}
                        />}
                </Form.Group>
                <div className='OrderModel-container__totalItems__locationCont2__error'>{deliveryTypeError}</div>
            </div>
            {deliveryType === "Urgent" && <div
                className="OrderModel-container__inputFiled"
                style={{
                    border:
                        urgentReasonError !== "" && urgentReason === "Select"
                            ? "2px solid red"
                            : "2px solid var(--primaryTextColor)"
                }}
            >
                <div className="OrderModel-container__inputFiled__label">
                    Reason<span style={{ color: "red" }}>*</span>
                </div>

                <div className="OrderModel-container__inputFiled__value">
                    <select
                        value={urgentReason}
                        onChange={(e) => {
                            const selectedValue = e.target.value
                            if (selectedValue === "Select") {
                                setUrgentReasonError("Please Select Destination Location");
                                setUrgentReason("Select");
                            } else {
                                setUrgentReasonError("");
                                setUrgentReason(selectedValue)
                            }
                        }}
                        className="OrderModel-container__inputFiled__value__input"
                    >
                        <option value="Select">Select</option>
                        {urgentReasonOption.map((option) => {
                            return (
                                <option key={option.label} value={option.label}>
                                    {option.label}
                                </option>
                            );
                        })}
                    </select>
                </div>

                <div className="OrderModel-container__inputFiled__error">
                    {urgentReasonError}
                </div>
            </div>}
            <div className='OrderModel-container__ListDownItems-container'>

                <div > <button className="OrderModel-container__ListDownItems-container__place-order"
                    onClick={
                        // recievedOn === "Call" ? handleAddCustomer : handlePlaceOrderClick
                        handlePlaceOrderClick}>
                    {recievedOn === "Shopify" ? "Place Order" : "Apply"}</button>
                </div>

            </div>
            {/* <div>  <button className='OrderModel-container__close'
                onClick={handleOrderModelClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg></button>
            </div> */}
            {/* <p style={{ visibility: check !== "" ? "visible" : "hidden" }} className='OrderModel-container__error'>{check ? check : "All fields required"}</p> */}
        </div>
    )
}

export default OrderModel;