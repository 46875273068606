import React from "react";
import "./BulkUpload.scss";
import ExportButton from "../CsvJsonConverter/ExportButton";
import TemplateButton from "../CsvJsonConverter/TemplateButton";
import ImportInventory from "../CsvJsonConverter/ImportInventory";
import ImportCategory from "../CsvJsonConverter/ImportCategory";
import TemplateCategory from "../CsvJsonConverter/TemplateCategory";

const BulkUpload = ({ select, userCookie, handleNotifyAlert, listItems }) => {
  return (
    <div className="BulkUpload-container">
      <div className="BulkUpload-container__title">
        Bulk Transfer<div style={{ color: "red" }}>*</div>
      </div>
      <div className="BulkUpload-container__buttons">
        {select === "Inventory List" && <ExportButton data={listItems} />}
        {(select === "Add Inventory" || select === "") && <TemplateButton />}

        {(select === "Add Inventory" || select === "") && (
          <ImportInventory
            userCookie={userCookie}
            handleNotifyAlert={handleNotifyAlert}
          />
        )}
        {(select === "Add Categories") && <TemplateCategory />}

        {select === "Add Categories" && (
          <ImportCategory
            userCookie={userCookie}
            handleNotifyAlert={handleNotifyAlert}
          />
        )}
      </div>

    </div>
  );
};

export default BulkUpload;
