import React from 'react';
import "./TemplateCategory.scss"

const TemplateCategory = () => {

    const headers = ['name'];


    const downloadTemplate = () => {

        const csvContent = headers.join(',') + '\n'; // Header row followed by a new line


        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });


        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', 'template.csv');
        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return (
        <button className="template-button" onClick={downloadTemplate}>
            Template
        </button>
    );
};

export default TemplateCategory;
