import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import ProductItemCard from '../ProductItemCard/ProductItemCard'
import "./DashboardCard.scss"
import { backEndIP } from '../configuration'
import { useNavigate } from 'react-router-dom';


const DashboardCard = ({ modulelabel, userCookie, setCreateOrderHome, navigateTo, setSelected, subContent, tabName, setCustomerDetailsPopup, setSelfOrderDetailsPopup }) => {
    const navigate = useNavigate()

    const handelOpenTab = (modulelabel) => {
        console.log("modulelabel", modulelabel)
        if (modulelabel === "Self order") {
            setSelfOrderDetailsPopup(true);
            setCreateOrderHome(false);
            setCustomerDetailsPopup(false);
        } else if (modulelabel === "Order for customer") {
            setCustomerDetailsPopup(true);
            setCreateOrderHome(false);
            setSelfOrderDetailsPopup(false);
        }
    }

    return (
        <div className="dashboardcard-container"  onClick={() => {
            if (subContent === undefined) {
                setSelected(tabName);
            } else {
                setSelected(tabName);
                subContent(tabName)
            }
            handelOpenTab(modulelabel)
            navigate(`/${navigateTo}`)
        }}>
            <div className='dashboardcard-container__title' >
                {modulelabel}
            </div>
            <div>
            <svg className='dashboardcard-container__arrow' width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="44" width="43.68" height="43.68" rx="21.84" transform="rotate(90 44 0)" fill="#FD6125" fill-opacity="0.05" />
                <path d="M10.32 23.335V20.3451H28.2594L20.0372 12.1228L22.16 10L34 21.84L22.16 33.68L20.0372 31.5572L28.2594 23.335H10.32Z" fill="#FD6125" />
            </svg>
            </div>
        </div>
    )
}

export default DashboardCard